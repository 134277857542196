import React, { memo } from 'react';

const Support = () => (
  <div className="support">
    <div className="support__text">
      <div>
        По любым вопросам обращайтесь по телефону
        <div>8 (800) 250-28-77 или почте support@agromon.ru</div>
      </div>
    </div>
  </div>
);

export default memo(Support);
