import { urlsWrapper } from 'config';
import { createSelector } from 'reselect';
import {
  saveData,
  createCommonListConstants,
  createCommonListReducer,
} from './utils';
import { commonGet, commonPost } from './api';

const trackers = createCommonListConstants('TRACKERS');

// reducer
export default createCommonListReducer(trackers);

// actions
export function getTrackers() {
  return saveData(
    `${urlsWrapper('trackers')}`,
    trackers.SET_LIST,
    trackers.SET_IS_PENDING
  );
}

export const getCustomTrackerTracks = ({ serial, source_id, ...query }) =>
  commonGet({
    url: `${urlsWrapper('trackersGeoprocesses')}/${source_id}/${serial}/tracks`,
    params: {
      start_at: (query || {}).start_at,
      end_at: (query || {}).end_at,
    },
  });

export const getUserTrackerTracks = (query) =>
  commonGet({
    url: `${urlsWrapper('trackersTracks')}`,
    params: {
      ...(query || {}),
    },
  });

export const getAdditionalTrackerInfo = (data) =>
  commonPost({
    url: `${urlsWrapper('trackersTracks')}/poll`,
    data,
    name: 'данные',
  });

export const getUserTrackerTypes = () =>
  commonGet({
    url: `${urlsWrapper('trackersTypes')}`,
  });

const shouldFetchTrackers = (state) => !state.trackers.list.length;

export const fetchTrackersIfNeeded = () => (dispatch, getState) => {
  const state = getState();
  return shouldFetchTrackers(state)
    ? dispatch(getTrackers())
    : Promise.resolve();
};

// selectors
export const getTrackersSelector = createSelector(
  [(state) => state.trackers.list, (state) => state.trackers.isPending],
  (list, isPending) => ({
    list,
    isPending,
  })
);
