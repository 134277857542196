import React, { useMemo } from 'react';
import Notification from './Notification';

const Notifications = ({
  notifications,
  handleNotificationClick,
  handleReadAllNotification,
  handleCloseClick,
}) => {
  const sortedNotifications = useMemo(
    () =>
      notifications.sort((a, b) => b.created_at.localeCompare(a.created_at)),
    [notifications]
  );

  return (
    <>
      <div className="notifications">
        {sortedNotifications.map((notification) => (
          <Notification
            key={notification.id}
            notification={notification}
            handleNotificationClick={handleNotificationClick}
            handleCloseClick={handleCloseClick}
          />
        ))}
      </div>
      <div className="notifications-clear-panel">
        <div
          className="btn comment-button"
          role="button"
          tabIndex={0}
          onClick={handleReadAllNotification}
        >
          Очистить все
        </div>
      </div>
    </>
  );
};

export default React.memo(Notifications);
