import React from 'react';
import { PUBLIC_URL } from 'env';

export const BrowserSupportBlank = () => {
  return (
    <div className="browser-support__wrap">
      {/* <div className="browser-support__logo">
        <img src={`${PUBLIC_URL}/images/browserSupport/logo.png`} alt="logo" />
      </div> */}
      <h1 className="browser-support__header">
        К сожалению, ваш браузер не поддерживается
      </h1>
      <div className="browser-support__text">
        Пожалуйста, используйте актуальную версию одного из следующих браузеров:
        Google Chrome, Mozilla FireFox, Яндекс Браузер
      </div>
      <div className="browser-support__browsers">
        <img
          src={`${PUBLIC_URL}/images/browserSupport/chrome.png`}
          alt="Chrome"
        />
        <img
          src={`${PUBLIC_URL}/images/browserSupport/firefox.png`}
          alt="Mozilla"
        />
        <img
          src={`${PUBLIC_URL}/images/browserSupport/yandex.png`}
          alt="Yandex"
        />
      </div>
    </div>
  );
};
