import React from 'react';
import { AutoSizer, List } from 'react-virtualized';

const DEFAULT_SIZE = 100;
export const DEFAULT_ROW_HEIGHT = 66;

const VirtualisedList = (props) => (
  <AutoSizer>
    {({ height, width }) => (
      <List
        width={width || DEFAULT_SIZE}
        height={height || DEFAULT_SIZE}
        ref={(list) =>
          typeof props.setListRef === 'function' && props.setListRef(list)
        }
        rowCount={props.list.length}
        rowHeight={props.rowHeight || DEFAULT_ROW_HEIGHT}
        rowRenderer={(params) =>
          props.rowRenderer({ ...params, list: props.list })
        }
        onScroll={(info) =>
          typeof props.onScroll === 'function' && props.onScroll(info)
        }
      />
    )}
  </AutoSizer>
);

export default VirtualisedList;
