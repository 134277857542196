import { urlsWrapper } from 'config';
import { serialize } from 'utils';
import moment from 'moment';
import {
  saveData,
  createCommonListConstants,
  createCommonListReducer,
  RESET_TYPES,
} from './utils';
import { commonDelete, commonGet, commonPost, commonPut } from './api';
import { setFormDataBlob, setPhotoScoutpoints } from './scouts';

const visitProposesConstants = createCommonListConstants('VISIT_PROPOSES');

// reducer
export const visitProposes = createCommonListReducer(visitProposesConstants);

// actions

export const getBayerTsVisitPurposes = () => {
  return saveData(
    urlsWrapper('visitPurposes'),
    visitProposesConstants.SET_LIST,
    visitProposesConstants.SET_IS_PENDING,
    RESET_TYPES.LIST
  );
};

export function getGroupVisitPurposes() {
  return commonGet({
    url: `${urlsWrapper('groupVisitPurposes')}`,
  });
}

const shouldFetchVisitPurposes = (state) =>
  state.visitProposes.list.length === 0;

export const fetchBayerTsVisitPurposesIfNeeded = () => (dispatch, getState) =>
  shouldFetchVisitPurposes(getState())
    ? dispatch(getBayerTsVisitPurposes())
    : Promise.resolve();

export const getBayerTsVisitsById = (id) => {
  return commonGet({
    url: `${urlsWrapper('bayerTsCustomers')}/${id}/visits`,
  });
};

export const getBayerTsVisits = (params) => {
  return commonGet({
    url: `${urlsWrapper('bayerTsCustomers')}/visits?${serialize(params)}`,
  });
};

const getFormData = ({ photos = [], timestamp, ...data }) => {
  const formData = new FormData();
  const scoutpoints = [];

  Array.from(photos).forEach((file) => {
    setPhotoScoutpoints(scoutpoints, file, file.name);
  });
  formData.append(
    'meta',
    new Blob(
      [
        JSON.stringify({
          ...data,
          scoutpoints,
          dt: moment(timestamp, 'YYYY-MM-DD')
            .set('hour', moment().format('HH'))
            .set('minute', moment().format('mm'))
            .set('second', moment().format('ss'))
            .toISOString(),
          web: true,
        }),
      ],
      {
        type: 'application/json',
      }
    ),
    'metafile'
  );
  Array.from(photos).forEach((file) => {
    setFormDataBlob(formData, file, file.name);
  });
  return formData;
};

export const createVisit = (visit, profileId) => {
  return commonPost({
    url: `${urlsWrapper('bayerTsCustomers')}/${profileId}/visits/upload`,
    data: getFormData(visit),
    name: 'осмотр',
  });
};

export const getBayerTsVisitsStats = (params) => {
  return commonGet({
    url: `${urlsWrapper('bayerTsCustomersVisitsStats')}?${serialize(params)}`,
  });
};

export const updateVisit = ({ id, ...data }, profileId) => {
  return commonPut({
    url: `${urlsWrapper('bayerTsCustomers')}/${profileId}/visits/${id}`,
    data,
    name: 'визит',
  });
};

export const deleteVisit = ({ id }, profileId) => {
  return commonDelete({
    url: `${urlsWrapper('bayerTsCustomers')}/${profileId}/visits/${id}`,
    name: 'визит',
  });
};
