import { urlsWrapper } from 'config';
import { serialize } from 'utils';
import {
  saveData,
  createCommonItemConstants,
  createCommonItemReducer,
} from './utils';

const groupsByTypeConstants = createCommonItemConstants('GROUPS_BY_TYPE');

// reducer
export default createCommonItemReducer(groupsByTypeConstants);

// actions
export function getGroupsByType(params) {
  return saveData(
    `${urlsWrapper('customerWorkrecordStats')}?${serialize(params)}`,
    groupsByTypeConstants.SET_ITEM,
    groupsByTypeConstants.SET_IS_PENDING
  );
}
