import getCurrentYear from 'utils/getCurrentYear';

export const getSowing = (field, globalFilterSeasons) => {
  const currentYear = getCurrentYear(globalFilterSeasons);
  return (field?.sowings || []).find((s) => s.year === currentYear);
};

export default (field, globalFilterSeasons) => {
  const sowing = getSowing(field, globalFilterSeasons);
  return sowing ? sowing.crop_id : null;
};
