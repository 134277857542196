import { urls } from 'config';
import { createAction } from 'redux-actions';
import { sortByDateKey } from 'utils';
import { isSceneAvailable } from 'utils/satelliteScenesFilter';
import { createSelector } from 'reselect';
import {
  createCommonListConstants,
  createCommonListReducer,
  resetData,
  RESET_TYPES,
} from './utils';
import { commonGet } from './api';

const constants = createCommonListConstants('SAT_DIVISION_SCENES');

// reducer
export default createCommonListReducer(constants);

const getSatDivisonScenes = (divisionId) =>
  commonGet({
    url: `${urls().satDivisions}/${divisionId}/scenes`,
  });

export function resetSatDivisionScenes() {
  return resetData(constants.SET_LIST, RESET_TYPES.LIST);
}

const getFilteredSceneByAvailable = (list, acc = {}) =>
  list?.length
    ? list.reduce((result, scene) => {
        if (isSceneAvailable(scene) && scene?.source_type) {
          // eslint-disable-next-line no-param-reassign
          result[scene.date] = {
            ...(result[scene.date] || {}),
            [scene.source_type]: {
              scenes: (
                result[scene.date]?.[scene.source_type]?.scenes || []
              ).concat(scene),
              field_count:
                (result[scene.date]?.[scene.source_type]?.field_count || 0) +
                scene?.field_count,
              source_type: scene.source_type,
            },
          };
        }
        return result;
      }, acc)
    : acc;

const getFilteredScenes = (acc) =>
  Object.entries(acc || {})
    .map(([date, data]) => ({
      date,
      ...data,
    }))
    .reduce(
      (result, { date, ...sourceTypes }) =>
        result.concat(
          Object.values(sourceTypes || {}).map((item) => ({ ...item, date }))
        ),
      []
    );

export const getSatDivisonScenesList = (divisionId) => async (dispatch) => {
  dispatch(createAction(constants.SET_IS_PENDING)(true));
  try {
    const scenes = await getSatDivisonScenes(divisionId).catch(() => ({}));
    const filteredScenes = getFilteredScenes(
      getFilteredSceneByAvailable(scenes.data?.data)
    );
    dispatch(
      createAction(constants.SET_LIST)(
        sortByDateKey(filteredScenes, 'date', 'desc')
      )
    );
  } catch (e) {
    dispatch(resetSatDivisionScenes());
    throw e;
  } finally {
    dispatch(createAction(constants.SET_IS_PENDING)(false));
  }
};

export const getMultipleSatDivisonScenesList = (divisionIds) => async (
  dispatch
) => {
  dispatch(createAction(constants.SET_IS_PENDING)(true));
  try {
    const responses = await Promise.all(
      (divisionIds || []).map((id) => getSatDivisonScenes(id).catch(() => []))
    ).catch(() => []);
    const filteredScenes = getFilteredScenes(
      responses.reduce(
        (result, item) => getFilteredSceneByAvailable(item?.data?.data, result),
        {}
      )
    );
    dispatch(
      createAction(constants.SET_LIST)(
        sortByDateKey(filteredScenes, 'date', 'desc')
      )
    );
  } catch (e) {
    dispatch(resetSatDivisionScenes());
    throw e;
  } finally {
    dispatch(createAction(constants.SET_IS_PENDING)(false));
  }
};

// selectors
export const getSatDivisionScenesSelector = createSelector(
  [
    (state) => state.satDivisionScenes.list,
    (state) => state.satDivisionScenes.isPending,
  ],
  (list, isPending) => ({
    list: [...list],
    isPending,
  })
);
