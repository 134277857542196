import { urls } from 'config';

const mocks = {
  [`${urls().groupsByType}`]: [
    {
      crop_id: 864,
      work_type_group_value: 2,
      work_records_count: 15, // Общее количество операций в группе
      start_ts: '2020-05-20T08:08:14.754Z', // null, если ни у одной работы в группе нет даты начала, иначе минимум из дат начала
      end_ts: '2020-05-22T08:08:14.754Z', // null, если ни у одной работы в группе нет даты конца, иначе максимум из дат конца
      fields_count: 16, // Количество полей, на которых есть операции из группы
      fields_area: 2000, // Площадь полей, на которых есть операции из группы
      work_records_completed: 5, // Количество завершенных операций в группе, завершённой считается операция с work_type_id = 1 (COMPLETED)
    },
    {
      crop_id: 860,
      work_type_group_value: 4,
      work_records_count: 35, // Общее количество операций в группе
      start_ts: '2020-05-22T08:08:14.754Z', // null, если ни у одной работы в группе нет даты начала, иначе минимум из дат начала
      end_ts: '2020-05-22T08:08:14.754Z', // null, если ни у одной работы в группе нет даты конца, иначе максимум из дат конца
      fields_count: 16, // Количество полей, на которых есть операции из группы
      fields_area: 4000, // Площадь полей, на которых есть операции из группы
      work_records_completed: 25, // Количество завершенных операций в группе, завершённой считается операция с work_type_id = 1 (COMPLETED)
    },
  ],
};

export const getMockUrl = (url) =>
  Object.keys(mocks).find((key) => url.includes(key));

export const mockPromise = (mockUrl) =>
  new Promise((resolve) => {
    setTimeout(
      () =>
        resolve({
          data: mocks[mockUrl],
        }),
      1000
    );
  });
