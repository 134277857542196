import React, { memo } from 'react';
import classNames from 'classnames';

const LoaderInner = memo(({ size, className, onlyLoader = false }) => (
  <div
    className={classNames(
      'loader-circle',
      {
        [`loader-circle--${size}`]: size,
        'loader-circle--only-loader': onlyLoader,
      },
      className
    )}
    aria-label="Загрузка"
  >
    <div className="loader-circle1 loader-child" />
    <div className="loader-circle2 loader-child" />
    <div className="loader-circle3 loader-child" />
    <div className="loader-circle4 loader-child" />
    <div className="loader-circle5 loader-child" />
    <div className="loader-circle6 loader-child" />
    <div className="loader-circle7 loader-child" />
    <div className="loader-circle8 loader-child" />
    <div className="loader-circle9 loader-child" />
    <div className="loader-circle10 loader-child" />
    <div className="loader-circle11 loader-child" />
    <div className="loader-circle12 loader-child" />
  </div>
));

const Loader = ({ fullScreen = false, ...props }) => {
  if (fullScreen) {
    return (
      <div className="loader-fullscreen">
        <LoaderInner {...props} />
      </div>
    );
  }
  return <LoaderInner {...props} />;
};

export default Loader;
