import { urlsWrapper } from 'config';
import { createSelector } from 'reselect';
import {
  saveData,
  createCommonListConstants,
  createCommonListReducer,
} from './utils';

const topics = createCommonListConstants('TOPICS');

// reducer
export default createCommonListReducer(topics);

// actions
export function getTopics() {
  return saveData(
    `${urlsWrapper('topics')}`,
    topics.SET_LIST,
    topics.SET_IS_PENDING
  );
}

// selectors
export const getTopicsSelector = createSelector(
  [(state) => state.topics],
  (topic) => topic
);
