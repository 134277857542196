import { combineReducers } from 'redux';
import { createSelector } from 'reselect';
import { urlsWrapper } from 'config';
import { serialize } from 'utils';

import moment from 'moment';
import {
  saveData,
  createCommonListConstants,
  createCommonListReducer,
  createCommonItemConstants,
  createCommonItemReducer,
} from './utils';
import { commonPut, commonDelete, commonPost, commonGet } from './api';

const scouts = createCommonListConstants('SCOUTS');
const all = createCommonListConstants('SCOUTS_ALL');
const statuses = createCommonListConstants('SCOUTS_STATUSES');
const byFieldId = createCommonListConstants('SCOUTS_BY_FIELD_ID');
const history = createCommonListConstants('SCOUTS_HISTORY');
const scoutById = createCommonItemConstants('SCOUT');

// reducer
export default combineReducers({
  scouts: createCommonListReducer(scouts),
  all: createCommonListReducer(all),
  statuses: createCommonListReducer(statuses),
  byFieldId: createCommonListReducer(byFieldId),
  history: createCommonListReducer(history),
  scoutById: createCommonItemReducer(scoutById),
});

export const setFormDataBlob = (form, blob, name) => {
  if (blob)
    form.append(
      name,
      new Blob([blob], {
        type: blob.type,
      }),
      name
    );
};

export const setPhotoScoutpoints = (scoutpoints, blob, name) => {
  if (blob) {
    scoutpoints.push({
      photo_ref: name,
      point_type: 'photo',
      timestamp: moment(new Date()).format(),
    });
  }
};

const setCropParamsScoutpoints = (scoutpoints, cropparams = []) => {
  const resultCropparams = cropparams
    .filter((cropparam) => {
      return !!cropparam?.value;
    })
    .map((item) => ({
      ...item,
      value: +item.value,
    }));

  if (resultCropparams.length) {
    scoutpoints.push({
      data: { cropparams: resultCropparams },
      point_type: 'cropparams',
      timestamp: moment(new Date()).format(),
    });
  }
};

const getFormData = ({ photos = [], cropparams = [], ...data }) => {
  const formData = new FormData();
  const scoutpoints = [];
  setCropParamsScoutpoints(scoutpoints, cropparams);

  Array.from(photos).forEach((file) => {
    setPhotoScoutpoints(scoutpoints, file, file.name);
  });
  formData.append(
    'meta',
    new Blob([JSON.stringify({ ...data, scoutpoints, web: true })], {
      type: 'application/json',
    }),
    'metafile'
  );
  Array.from(photos).forEach((file) => {
    setFormDataBlob(formData, file, file.name);
  });
  return formData;
};

export const uploadScout = (scout) => () => {
  return commonPost({
    url: urlsWrapper('scoutsUpload'),
    data: getFormData(scout),
    name: 'осмотр',
  });
};

export function getScoutsAll(params) {
  return saveData(
    `${urlsWrapper('scoutsAll')}?${serialize(params)}`,
    all.SET_LIST,
    all.SET_IS_PENDING
  );
}

export const updateScout = (fieldId, scoutId, data) => () =>
  commonPut({
    url: `${urlsWrapper('fields')}/${fieldId}/scouts/${scoutId}`,
    data,
    name: 'осмотр',
  });

export const deleteScout = (fieldId, scoutId) => () =>
  commonDelete({
    url: `${urlsWrapper('fields')}/${fieldId}/scouts/${scoutId}`,
  });

export function getScoutsByFieldId(fieldId) {
  return saveData(
    `${urlsWrapper('fields')}/${fieldId}/scouts`,
    byFieldId.SET_LIST,
    byFieldId.SET_IS_PENDING
  );
}

export function getScout(fieldId, scoutId, save = true) {
  const url = `${urlsWrapper('fields')}/${fieldId}/scouts/${scoutId}`;
  if (save) {
    return saveData(url, scoutById.SET_ITEM, scoutById.SET_IS_PENDING);
  }
  return commonGet({
    url,
  });
}

export const getScoutsByFieldIdSelector = createSelector(
  [(state) => state.scouts.byFieldId],
  (list) => list
);
