import { urlsWrapper } from 'config';
import { createSelector } from 'reselect';
import {
  saveData,
  createCommonListConstants,
  createCommonListReducer,
} from './utils';

const constants = createCommonListConstants('DIVISION_GROUPS');

// reducer
export default createCommonListReducer(constants);

// actions
export function getDivisionGroups() {
  return saveData(
    urlsWrapper('divisionGroups'),
    constants.SET_LIST,
    constants.SET_IS_PENDING
  );
}

// selectors
export const getDivisionsGroupsSelector = createSelector(
  [
    (state) => state.divisionGroups.list,
    (state) => state.divisionGroups.isPending,
  ],
  (list, isPending) => ({
    list,
    isPending,
  })
);
