import { urlsWrapper } from 'config';
import { createSelector } from 'reselect';
import { createAction } from 'redux-actions';
import {
  saveData,
  createCommonListConstants,
  createCommonListReducer,
} from './utils';
import { commonPost, commonDelete, commonPut, commonGet } from './api';

const constants = createCommonListConstants('USERS');
const constantsAllowedInfo = createCommonListConstants('USERS_ALLOWED_INFO');

// reducer
export const users = createCommonListReducer(constants);
export const allowedUsersInfo = createCommonListReducer(constantsAllowedInfo);

// actions
export function getUsersList() {
  return saveData(
    urlsWrapper('users'),
    constants.SET_LIST,
    constants.SET_IS_PENDING
  );
}

export function getUserPermissions() {
  return saveData(
    `${urlsWrapper('users')}/permissions`,
    constantsAllowedInfo.SET_LIST,
    constantsAllowedInfo.SET_IS_PENDING
  );
}

export function saveUserList(data) {
  return (dispatch) => {
    dispatch(createAction(constants.SET_LIST)(data));
  };
}

export function saveAllowedUserList(data) {
  return (dispatch) => {
    dispatch(createAction(constantsAllowedInfo.SET_LIST)(data));
  };
}

export const addUser = (data) => () =>
  commonPost({ url: urlsWrapper('users'), data, name: 'пользователя' });

export const updateUser = (id, data) => () =>
  commonPut({
    url: `${urlsWrapper('users')}/${id}`,
    data,
    name: 'пользователя',
  });

export const getUserInfo = (userId) =>
  commonGet({
    url: `${urlsWrapper('users')}/${userId}`,
    name: 'пользователя',
  });

export const getUserCommonInfo = (userId) =>
  commonGet({
    url: `${urlsWrapper('usersCommon')}/${userId}`,
    name: 'пользователя',
  });

export const deleteUser = (id) => () =>
  commonDelete({ url: `${urlsWrapper('users')}/${id}` });

// selector
export const getUsersSelector = createSelector(
  [(state) => state.users],
  (usersInfo) => usersInfo
);

export const getUsersAllowedSelector = createSelector(
  [(state) => state.allowedUsersInfo],
  (usersAllowed) => usersAllowed
);
