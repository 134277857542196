import { createAction } from 'redux-actions';
import { combineReducers } from 'redux';
import { urls } from 'config';
import { commonGet } from './api';

const constants = {
  SET_CUSTOMER: 'DIVISIONS_BY_CUSTOMER_SET_CUSTOMER',
  RESET_CUSTOMERS: 'DIVISIONS_BY_CUSTOMER_RESET_CUSTOMERS',
  SET_IS_PENDING: 'DIVISIONS_BY_CUSTOMER_SET_IS_PENDING',
};

// reducers
const divisions = (state = null, action) => {
  switch (action.type) {
    case constants.SET_CUSTOMER:
      return { ...state, ...action.payload };
    case constants.RESET_CUSTOMERS:
      return null;
    default:
      return state;
  }
};

const isPending = (state = false, action) => {
  switch (action.type) {
    case constants.SET_IS_PENDING:
      return action.payload;
    default:
      return state;
  }
};

export default combineReducers({
  divisions,
  isPending,
});

// action creators
export const resetCustomer = createAction(constants.RESET_CUSTOMERS);

// actions
export const getDivisionsByCustomer = (customerId) => async (dispatch) => {
  dispatch(createAction(constants.SET_IS_PENDING)(true));
  try {
    const url = `${urls().customers}/${customerId}/divisions`;
    const response = await commonGet({ url });
    dispatch(
      createAction(constants.SET_CUSTOMER)({ [customerId]: response.data })
    );
    return response;
  } finally {
    dispatch(createAction(constants.SET_IS_PENDING)(false));
  }
};
