/* eslint-disable react/no-danger */
import React, { useState, useEffect } from 'react';
import { baseUrl } from 'config';
import { useSelector } from 'react-redux';
import { getCropsSelector } from 'modules/crops';

function SvgImage(props) {
  const [svgContent, setSvgContent] = useState(null);
  const crops = useSelector(getCropsSelector).list;
  const crop = crops.find((cropItem) => cropItem.id === props.sowing?.crop_id);
  const color = crop?.color || '#fff';

  useEffect(() => {
    // Make a fetch request to your server to get the SVG file
    setSvgContent(null);
    fetch(baseUrl + props.svgPath)
      .then((response) => response.text())
      .then((data) => {
        // Set the SVG content in state
        setSvgContent(data);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error('Error fetching SVG:', error);
      });
  }, [props.svgPath]);

  return (
    <>
      {svgContent && (
        <div
          className="svg-image"
          style={{ fill: color }}
          dangerouslySetInnerHTML={{ __html: svgContent }}
        />
      )}
    </>
  );
}

export default SvgImage;
