import reducer from 'reducer';
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { env_type } from 'env';

let store = {};
export const configureStore = () => {
  const composeEnhancers =
    env_type === 'production'
      ? compose
      : composeWithDevTools({
          // other compose enhancers if any
          // Specify here other options if needed
        });

  // Setting middlewares
  const middlewares = [thunk];
  store = createStore(
    reducer,
    composeEnhancers(applyMiddleware(...middlewares))
  );

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('../reducer', () => {
      /* eslint-disable global-require */
      const nextReducer = require('../reducer').default;
      store.replaceReducer(nextReducer);
    });
  }

  return store;
};

export function getStore() {
  return store;
}
