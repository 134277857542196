import React, { useCallback, useMemo } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { getFieldTabsSelector, setMapFilters } from 'modules/mapFilters';
import { getFilterSeasonSelector } from 'modules/globalFilters';
import { getFieldsSelector } from 'modules/fields';
import Tooltips from 'components/common/Tooltips';
import FieldListTab from './FieldListTab';

export const TABS = {
  ALL: 'ALL',
  ACTUAL: 'ACTUAL',
};

const FieldListTabs = () => {
  const dispatch = useDispatch();
  const activeTabName = useSelector(getFieldTabsSelector);
  const season = useSelector(getFilterSeasonSelector);
  const { list: fields } = useSelector(getFieldsSelector);

  const fieldsWithActualSeasons = useMemo(() => {
    return fields.some((field) => {
      return field.actual_seasons.some((seasons) => {
        return seasons.some((cur) => !!cur);
      });
    });
  }, [fields]);

  const onChangeTabCb = useCallback((fieldsTab) => {
    dispatch(setMapFilters({ fieldsTab }));
  }, []);

  const tabs = useMemo(() => {
    const currentYear =
      Number.parseInt(season, 10) || moment(new Date()).year();
    return fieldsWithActualSeasons
      ? [
          {
            title: (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '6px',
                  justifyContent: 'center',
                }}
              >
                Все поля
                <div>
                  <Tooltips
                    placement="bottom"
                    content='По умолчанию в варианте "Только сезон" показываются поля, которые актуальны в выбранном сезоне. Периоды актуальности настраиваются на вклакде "Прочее" страницы поля. Переключение на "Все поля" позволит увидеть в том числе и те поля, которые не актуальны в сезоне.'
                  />
                </div>
              </div>
            ),
            id: TABS.ALL,
          },
          {
            title: `Только сезон ${currentYear}`,
            id: TABS.ACTUAL,
          },
        ]
      : [];
  }, [fieldsWithActualSeasons, season]);

  return (
    <div className="field-list-tabs__wrapper">
      {tabs.map(({ title, id }, index) => (
        <FieldListTab
          tabIndex={index}
          id={id}
          isActive={id === activeTabName}
          onChangeTabCb={onChangeTabCb}
          title={title}
          key={id}
        />
      ))}
    </div>
  );
};

export default React.memo(FieldListTabs);
