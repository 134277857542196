import componentLoader from 'utils/componentLoader';
import { env_externalservices } from 'env';
import { getAdvisorId } from 'utils';
import { baierAdvisorId } from 'config';

const Customers = componentLoader(() =>
  import(
    /* webpackChunkName: "advisor-Customers" */ 'components/advisor/Customers'
  )
);
const Users = componentLoader(() =>
  import(/* webpackChunkName: "customer-Users" */ 'components/customer/Users')
);
const Dictionaries = componentLoader(() =>
  import(
    /* webpackChunkName: "advisor-Dictionaries" */ 'components/common/Dictionaries'
  )
);
const Analytics = componentLoader(() =>
  import(
    /* webpackChunkName: "advisor-Analytics" */ 'components/advisor/Analytics'
  )
);
const Visits = componentLoader(() =>
  import(/* webpackChunkName: "advisor-Visits" */ 'components/advisor/Visits')
);

const childrenRoutes = [
  {
    title: 'Клиенты',
    path: '/advisor',
    component: Customers,
    exact: true,
    icon: 'fa fa-sitemap',
  },
  {
    title: 'Пользователи',
    path: '/advisor/users',
    component: Users,
    exact: false,
    icon: 'fa fa-user',
    hideForNotAdmins: true,
    hideSection: () => env_externalservices !== 'true',
  },
  {
    title: 'Визиты',
    path: '/advisor/visits',
    component: Visits,
    exact: false,
    icon: 'fa fa-handshake',
    hideSection: () =>
      env_externalservices !== 'true'
        ? false
        : ![+getAdvisorId()].includes(baierAdvisorId),
  },
  {
    title: 'Справочники',
    path: '/advisor/dict',
    component: Dictionaries,
    exact: false,
    icon: 'fa fa-list',
  },
  {
    title: 'Аналитика',
    path: '/advisor/analytics',
    component: Analytics,
    exact: false,
    icon: 'fa fa-chart-bar',
  },
];

/**
 * Possible values for children object:
 * title - title of the section
 * path - url path to the section
 * component - component to render
 * exact - render child route components on it's own urls
 * icon - icon to show in a menu
 * hideSection - hide or show section for production
 */

export default [
  {
    title: '',
    children: childrenRoutes,
  },
];
