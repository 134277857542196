import { urls } from 'config';
import {
  saveData,
  createCommonListConstants,
  createCommonListReducer,
} from './utils';

const constants = createCommonListConstants('ADVISORS');

// reducer
export default createCommonListReducer(constants);

// actions
export function getAdvisors() {
  return saveData(
    urls().advisors,
    constants.SET_LIST,
    constants.SET_IS_PENDING
  );
}
