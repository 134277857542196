export const getDivisions = ({
  divisions,
  divisionGroups,
  value = [0],
  isSelect = false,
}) => {
  let title = '';
  let divisionsOptions = [];
  let id = null;
  let isDivisionGroup = false;
  if (divisions.length === 1) {
    title = divisions[0].name;
    if (isSelect) {
      id = divisions[0].id;
      divisionsOptions = divisions;
    }
  } else if (divisions.length > 1) {
    // if amount of divisions more than 1 and no groups available
    if (divisionGroups.length === 0) {
      divisionsOptions = [
        {
          id: 0,
          name: 'Все хозяйства',
        },
        ...divisions,
      ];
      const option = divisionsOptions.find(
        (d) => Array.isArray(value) && value.indexOf(d.id) > -1
      );
      if (option) {
        title = option.name;
        // eslint-disable-next-line prefer-destructuring
        id = option.id;
      }
      // if groups are available for a user
    } else {
      const groupedDivisions = divisionGroups.map((group) => ({
        ...group,
        divisions: divisions.filter((d) => d.divisiongroup_id === group.id),
      }));
      divisionsOptions = [
        {
          id: 0,
          name: 'Все хозяйства',
        },
        ...groupedDivisions,
        ...divisions.filter((d) => d.divisiongroup_id == null),
      ];
      const division = divisions.find(
        (d) => Array.isArray(value) && value.indexOf(d.id) > -1
      );
      if (!division) {
        title = 'Все хозяйства';
        id = 0;
      } else if (division.divisiongroup_id == null || value.length === 1) {
        title = division.name;
        // eslint-disable-next-line prefer-destructuring
        id = division.id;
        isDivisionGroup = false;
      } else {
        const group = divisionGroups.find(
          (g) => g.id === division.divisiongroup_id
        );
        title = group.name;
        // eslint-disable-next-line prefer-destructuring
        id = group.id;
        isDivisionGroup = true;
      }
    }
  }
  return { title, divisionsOptions, id, isDivisionGroup };
};
