import { urlsWrapper } from 'config';
import {
  saveData,
  createCommonListConstants,
  createCommonListReducer,
} from './utils';
import { commonPost } from './api';

const constants = createCommonListConstants('COMMENTS');

// reducer
export default createCommonListReducer(constants);

// actions
export function getComments(fieldId, scoutId) {
  return saveData(
    `${urlsWrapper('fields')}/${fieldId}/scouts/${scoutId}/comments`,
    constants.SET_LIST,
    constants.SET_IS_PENDING
  );
}

export const addComment = (fieldId, scoutId, data) => () =>
  commonPost({
    url: `${urlsWrapper('fields')}/${fieldId}/scouts/${scoutId}/comments`,
    data,
    name: 'комментарий',
  });
