export const isSceneAvailable = (scene) =>
  scene.status === 'PROCESSED' || scene.status === 'PREPROCESSED';

export const filterByAvgCloudCoverage = (scene, cloudCoverLimit) => {
  const { scenes = [] } = scene;
  const ccSum = scenes.reduce(
    (acc, field) => acc + Number(field.cloud_coverage),
    0
  );
  const ccAverage =
    scenes.length > 0
      ? Math.ceil(Math.round((ccSum / scenes.length) * 1000) / 10)
      : 0;
  return ccAverage <= cloudCoverLimit;
};

export const filterByCC = (scene, cloudCoverLimit) =>
  +scene.cloud_coverage <= cloudCoverLimit / 100;

export const filterBySourceType = (scene, sourceType) =>
  scene.source_type === sourceType;

export const filterScene = (scene, productId) => {
  switch (productId) {
    case 'management_zone_scene':
      return filterByCC(scene, 1) && filterBySourceType(scene, 'SENTINEL2');
    default:
      return filterByAvgCloudCoverage(scene, 25);
  }
};
