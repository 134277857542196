import { urls } from 'config';
import {
  saveData,
  createCommonListConstants,
  createCommonListReducer,
} from './utils';

const constants = createCommonListConstants('REGIONS');
const bayerRegionConstants = createCommonListConstants('BAYER_REGIONS');

// reducer
export const regions = createCommonListReducer(constants);
export const bayerRegions = createCommonListReducer(bayerRegionConstants);

// actions
export function getRegions() {
  return saveData(urls().regions, constants.SET_LIST, constants.SET_IS_PENDING);
}

export function getBayerRegions() {
  return saveData(
    urls().bayerRegions,
    bayerRegionConstants.SET_LIST,
    bayerRegionConstants.SET_IS_PENDING
  );
}

const shouldFetchRegions = (state) => state.regions.list.length === 0;

export const fetchRegionsIfNeeded = () => (dispatch, getState) =>
  shouldFetchRegions(getState()) ? dispatch(getRegions()) : Promise.resolve();

const shouldFetchBayerRegions = (state) => state.bayerRegions.list.length === 0;

export const fetchBayerRegionsIfNeeded = () => (dispatch, getState) =>
  shouldFetchBayerRegions(getState())
    ? dispatch(getBayerRegions())
    : Promise.resolve();
