import { combineReducers } from 'redux';
import { createSelector } from 'reselect';
import { urls } from 'config';
import { serialize, sortListByImportantAndName } from 'utils';
import { getAuthUserCountry } from 'utils/getAuthUserCountry';
import { convertDictionaryFilters } from 'utils/convertDictionaryFilters';
import {
  saveData,
  createCommonListConstants,
  createCommonListReducer,
  createCommonItemReducer,
  createCommonItemConstants,
  fetchDictionariesItemIfNotExist,
} from './utils';
import { commonGet } from './api';

const pesticides = createCommonListConstants('PESTICIDES');
const pesticideItem = createCommonItemConstants('PESTICIDE_ITEM');
const vendors = createCommonListConstants('PESTICIDE_VENDORS');

// reducer
export default combineReducers({
  pesticides: createCommonListReducer(pesticides, sortListByImportantAndName),
  pesticideItem: createCommonItemReducer(pesticideItem),
  vendors: createCommonListReducer(vendors),
});

// actions
export function getPesticides({ embed = 'none', ...additional }) {
  const params = serialize({ embed, ...additional });
  return saveData(
    `${urls().pesticides}?${params}`,
    pesticides.SET_LIST,
    pesticides.SET_IS_PENDING
  );
}

function getPesticideItem(itemId) {
  return commonGet({ url: `${urls().pesticides}/${itemId}` });
}

export function getPesticideVendors() {
  return saveData(
    urls().pesticideVendors,
    vendors.SET_LIST,
    vendors.SET_IS_PENDING
  );
}

const shouldFetchPesticides = (state) =>
  state.pesticides.pesticides.list.length === 0;

export const fetchPesticidesIfNeeded = (params) => (dispatch, getState) => {
  const state = getState();
  const countries = getAuthUserCountry(
    state.auth.currentCustomer,
    state.auth.currentAdvisor
  );
  return shouldFetchPesticides(state)
    ? dispatch(getPesticides(Object.assign(params || {}, { countries })))
    : Promise.resolve();
};

export const getPesticidesIdsBySearch = (query = '', filters = {}) => (
  dispatch,
  getState
) => {
  const state = getState();
  const countries = getAuthUserCountry(
    state.auth.currentCustomer,
    state.auth.currentAdvisor
  );

  return commonGet({
    url: `${urls().pesticides}/search/ids`,
    params: {
      query: query || undefined,
      ...convertDictionaryFilters(filters),
      countries,
    },
  });
};

const shouldFetchPesticideVendors = (state) =>
  state.pesticides.vendors.list.length === 0;

export const fetchPesticideVendorsIfNeeded = () => (dispatch, getState) =>
  shouldFetchPesticideVendors(getState())
    ? dispatch(getPesticideVendors())
    : Promise.resolve();

export const fetchPesticideItemIfNeeded = (itemId) =>
  fetchDictionariesItemIfNotExist({
    itemId,
    itemStateKey: 'pesticides',
    itemSubStateKey: 'pesticideItem',
    itemConstants: pesticideItem,
    getDictItem: getPesticideItem,
  });

export const getPesticidesSelector = createSelector(
  [(state) => state.pesticides?.pesticides],
  (list) => list
);

export const getPesticidesVendorListSelector = createSelector(
  [(state) => state.pesticides?.vendors.list],
  (list) => list
);
