import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

export const TABS = {
  ALL: 'ALL',
  ACTUAL: 'ACTUAL',
};

const propTypes = {
  title: PropTypes.string.isRequired,
  tabIndex: PropTypes.number.isRequired,
  id: PropTypes.string.isRequired,
  isActive: PropTypes.bool,
  onChangeTabCb: PropTypes.func.isRequired,
};

const defaultProps = {
  isActive: false,
};

const FieldListTab = ({ tabIndex, onChangeTabCb, id, isActive, title }) => (
  <div
    className={classNames('field-list-tabs__item', isActive ? 'active' : '')}
    onClick={() => onChangeTabCb(id)}
    role="button"
    tabIndex={tabIndex}
  >
    {title}
  </div>
);

FieldListTab.propTypes = propTypes;
FieldListTab.defaultProps = defaultProps;

export default React.memo(FieldListTab);
