import { createSelector } from 'reselect';
import { constants as globalFilter } from 'modules/globalFilters';
import { TABS } from 'components/customer/Map/FieldListTabs';

export const mapFilter = {
  SET_FILTERS: 'MAP_FILTERS_SET_FILTERS',
  RESET_FILTERS: 'MAP_FILTERS_RESET_FILTERS',
};

// reducer
const defaultState = {
  cropValue: undefined,
  scoutsTimeValue: undefined,
  filterDivisionsSelected: [0],
  breedValue: undefined,
  sowedAtFrom: undefined,
  sowedAtTo: undefined,
  fieldYieldFrom: undefined,
  fieldYieldTo: undefined,
  isPlotsCount: undefined,
  fieldsTab: TABS.ACTUAL,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case mapFilter.RESET_FILTERS:
      return { ...defaultState };
    case mapFilter.SET_FILTERS:
      return { ...state, ...action.payload };
    case globalFilter.SET_FILTER_DIVISIONS:
      return { ...state, filterDivisionsSelected: action.filterState };
    default:
      return state;
  }
};

// actions
export function setMapFilters(payload) {
  return {
    type: mapFilter.SET_FILTERS,
    payload,
  };
}

export function resetMapFilters() {
  return {
    type: mapFilter.RESET_FILTERS,
  };
}

// selector
export const getFieldTabsSelector = createSelector(
  [(state) => state.mapFilters.fieldsTab],
  (fieldsTab) => fieldsTab
);

export const getMapFiltersSelector = createSelector(
  [(state) => state.mapFilters],
  (mapFilters) => mapFilters
);
