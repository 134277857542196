import { urls } from 'config';
import { sortListByImportantAndName } from 'utils';
import {
  saveData,
  createCommonListConstants,
  createCommonListReducer,
  createCommonItemReducer,
  createCommonItemConstants,
  fetchDictionariesItemIfNotExist,
} from './utils';
import { commonGet } from './api';

const constants = createCommonListConstants('WEEDS');
const itemConstants = createCommonItemConstants('WEEED_ITEM');

// reducer
export const weeds = createCommonListReducer(
  constants,
  sortListByImportantAndName
);
export const weedItem = createCommonItemReducer(itemConstants);

// actions
export function getWeeds(embedParam = 'none') {
  return saveData(
    `${urls().weeds}?embed=${embedParam}`,
    constants.SET_LIST,
    constants.SET_IS_PENDING
  );
}

export function getWeedsIdsBySearch(query = '') {
  return commonGet({
    url: `${urls().weeds}/search/ids`,
    params: {
      query,
    },
  });
}

export function getWeedItem(itemId) {
  return commonGet({ url: `${urls().weeds}/${itemId}` });
}

const shouldFetchWeeds = (state) => state.weeds.list.length === 0;

export const fetchWeedsIfNeeded = (embedParam) => (dispatch, getState) =>
  shouldFetchWeeds(getState())
    ? dispatch(getWeeds(embedParam))
    : Promise.resolve();

export const fetchWeedItemIfNeeded = (itemId) =>
  fetchDictionariesItemIfNotExist({
    itemId,
    itemStateKey: 'weedItem',
    itemSubStateKey: null,
    itemConstants,
    getDictItem: getWeedItem,
  });
