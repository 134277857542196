import { urls } from 'config';
import {
  saveData,
  createCommonListConstants,
  createCommonListReducer,
} from './utils';

const constants = createCommonListConstants('DISTRICTS');

// reducer
export default createCommonListReducer(constants);

// actions
export function getDistricts() {
  return saveData(
    urls().districts,
    constants.SET_LIST,
    constants.SET_IS_PENDING
  );
}

const shouldFetchDistricts = (state) => state.districts.list.length === 0;

export const fetchDistrictsIfNeeded = () => (dispatch, getState) =>
  shouldFetchDistricts(getState())
    ? dispatch(getDistricts())
    : Promise.resolve();
