import { urls } from 'config';
import { sortListByImportantAndName } from 'utils';
import { createSelector } from 'reselect';
import {
  saveData,
  createCommonListConstants,
  createCommonListReducer,
  createCommonItemReducer,
  createCommonItemConstants,
  fetchDictionariesItemIfNotExist,
  resetData,
  RESET_TYPES,
} from './utils';
import { commonGet } from './api';

const constants = createCommonListConstants('CROPS');
const settingsConstants = createCommonItemConstants('CROPS_SETTINGS');
const itemConstants = createCommonItemConstants('CROP_ITEM');

const defaultCropParamsConstans = createCommonItemConstants(
  'DEFAULT_CROP_PARAMS'
);

// reducer
export const cropParams = createCommonItemReducer(defaultCropParamsConstans);
export const crops = createCommonListReducer(
  constants,
  sortListByImportantAndName
);
export const cropColorSettings = createCommonItemReducer(settingsConstants);
export const cropItem = createCommonItemReducer(itemConstants);

// actions
export function getCrops(embedParam = 'none') {
  return saveData(
    `${urls().crops}?embed=${embedParam}`,
    constants.SET_LIST,
    constants.SET_IS_PENDING
  );
}

export function getSettingsCrops(customerId) {
  return saveData(
    `${urls(customerId).cropcolorsSettings}`,
    settingsConstants.SET_ITEM,
    settingsConstants.SET_IS_PENDING
  );
}

export function resetSettingsCrops() {
  return resetData(settingsConstants.SET_ITEM, RESET_TYPES.ITEM);
}

export function getCropParamsSchema() {
  return saveData(
    `${urls().cropParamsSchema}`,
    defaultCropParamsConstans.SET_ITEM,
    defaultCropParamsConstans.SET_IS_PENDING
  );
}

export function getCropsIdsBySearch(query = '') {
  return commonGet({
    url: `${urls().crops}/search/ids`,
    params: {
      query,
    },
  });
}

export function getCropItem(itemId) {
  return commonGet({ url: `${urls().crops}/${itemId}` });
}

const shouldFetchCrops = (state) => state.crops.list.length === 0;

const shouldFetchCropParams = (state) =>
  !state.cropParams.item?.cropparamsschema?.length;

export const fetchCropsIfNeeded = (embedParam) => (dispatch, getState) =>
  shouldFetchCrops(getState())
    ? dispatch(getCrops(embedParam))
    : Promise.resolve();

export const fetchCropParamsIfNeeded = (embedParam) => (dispatch, getState) => {
  return shouldFetchCropParams(getState())
    ? dispatch(getCropParamsSchema(embedParam))
    : Promise.resolve();
};

export const fetchCropItemIfNeeded = (itemId) =>
  fetchDictionariesItemIfNotExist({
    itemId,
    itemStateKey: 'cropItem',
    itemSubStateKey: null,
    itemConstants,
    getDictItem: getCropItem,
  });

// selectors
export const getCropsSelector = createSelector(
  [
    (state) => state.crops.list,
    (state) => state.crops.isPending,
    (state) => state.cropColorSettings.item,
  ],
  (list, isPending, cropColorSetting) => {
    return {
      list: (list || []).map((item) => {
        if ((cropColorSetting || {})?.value?.[item.id]) {
          return {
            ...item,
            color: (cropColorSetting || {})?.value?.[item.id],
          };
        }

        return item;
      }),
      isPending,
    };
  }
);

export const getСropParamsSelector = createSelector(
  [(state) => state.cropParams.item, (state) => state.cropParams.isPending],
  (item, isPending) => ({
    list: item.cropparamsschema ?? [],
    isPending,
  })
);
