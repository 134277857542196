import React from 'react';

class DivisionGroups extends React.PureComponent {
  state = {
    isCollapsed: true,
  };

  handleCollapseClick = (e) => {
    e.stopPropagation();
    e.preventDefault();

    this.setState(({ isCollapsed }) => ({
      isCollapsed: !isCollapsed,
    }));
  };

  handleNameClick = (division) => {
    const divisionIds =
      division.divisions && division.divisions.length > 0
        ? division.divisions.map((d) => d.id)
        : division.id === 0
        ? [0]
        : [division.id];
    this.props.handleChange(divisionIds);
  };

  render() {
    const { division } = this.props;

    return (
      <div className="divisions-dropdown">
        <div
          className="divisions-collapse"
          onClick={() => this.handleNameClick(division)}
          role="button"
          tabIndex={0}
        >
          {division.divisions && division.divisions.length > 0 && (
            <div
              className="divisions-collapse__icon"
              onClick={this.handleCollapseClick}
              role="button"
              tabIndex={0}
            >
              <i
                className={`fa ${
                  this.state.isCollapsed ? 'fa-angle-right' : 'fa-angle-down'
                }`}
              />
            </div>
          )}
          {division?.name}
        </div>
        {!this.state.isCollapsed &&
          (division.divisions || []).map((d) => (
            <div
              key={`${division.id}-${d.id}`}
              onClick={() => this.handleNameClick(d)}
              className="divisions-collapse divisions-dropdown__group-children"
              role="button"
              tabIndex={0}
            >
              {d.name}
            </div>
          ))}
      </div>
    );
  }
}

export default DivisionGroups;
