import { urlsWrapper } from 'config';
import { combineReducers } from 'redux';
import { createSelector } from 'reselect';
import {
  saveData,
  createCommonListConstants,
  createCommonListReducer,
  createCommonItemReducer,
  createCommonItemConstants,
  resetData,
  RESET_TYPES,
} from './utils';
import { commonPost, commonDelete, commonPut, commonGet } from './api';

const plots = createCommonListConstants('PLOTS');
const plotsByFieldId = createCommonListConstants('PLOTS_BY_FIELD_ID');
const plotsGeojson = createCommonItemConstants('PLOTS_GEOJSON');

// reducer
export default combineReducers({
  plots: createCommonListReducer(plots),
  plotsByFieldId: createCommonListReducer(plotsByFieldId),
  plotsGeojson: createCommonItemReducer(plotsGeojson),
});

// actions
export function getPlotsByFieldId(fieldId) {
  return saveData(
    `${urlsWrapper('fields')}/${fieldId}/plots?embed=geojson`,
    plotsByFieldId.SET_LIST,
    plotsByFieldId.SET_IS_PENDING
  );
}

export function getPlotById(id, fieldId) {
  return commonGet({ url: `${urlsWrapper('fields')}/${fieldId}/plots/${id}` });
}

export function getPlots() {
  return saveData(
    `${urlsWrapper('plots')}?embed=geojson`,
    plots.SET_LIST,
    plots.SET_IS_PENDING
  );
}

export const addPlot = (fieldId, data) => () =>
  commonPost({
    url: `${urlsWrapper('fields')}/${fieldId}/plots`,
    data,
    name: 'участок',
  });

export const updatePlot = (fieldId, plotId, data) => () =>
  commonPut({
    url: `${urlsWrapper('fields')}/${fieldId}/plots/${plotId}`,
    data,
    name: 'участок',
  });

export const deletePlot = (fieldId, plotId) => () =>
  commonDelete({
    url: `${urlsWrapper('fields')}/${fieldId}/plots/${plotId}`,
  });

export function getPlotsGeojson() {
  return saveData(
    `${urlsWrapper('plotsGeojson')}`,
    plotsGeojson.SET_ITEM,
    plotsGeojson.SET_IS_PENDING
  );
}

export function resetPlotsByFieldId() {
  return resetData(plotsByFieldId.SET_LIST, RESET_TYPES.LIST);
}

// selectors
export const getPlotsSelector = createSelector(
  [(state) => state.plots.plots.list, (state) => state.plots.plots.isPending],
  (list, isPending) => ({
    list,
    isPending,
  })
);

export const getPlotsGeojsonSelector = createSelector(
  [
    (state) => state.plots.plotsGeojson.item,
    (state) => state.plots.plotsGeojson.isPending,
  ],
  (item, isPending) => ({
    item,
    isPending,
  })
);
