import { urls, urlsWrapper } from 'config';
import { createSelector } from 'reselect';
import {
  saveData,
  createCommonListConstants,
  createCommonListReducer,
} from './utils';
import { commonPost, commonPut, commonDelete } from './api';

const constants = createCommonListConstants('DIVISIONS');

// reducer
export default createCommonListReducer(constants);

// actions
export function getDivisions() {
  return saveData(
    urlsWrapper('divisions'),
    constants.SET_LIST,
    constants.SET_IS_PENDING
  );
}

export const addDivision = (data, customerId) => () =>
  commonPost({
    url: customerId ? urls(customerId).divisions : urlsWrapper('divisions'),
    data,
    name: 'хозяйство',
  });

export const updateDivision = (divisionId, data) => () =>
  commonPut({
    url: `${urlsWrapper('divisions')}/${divisionId}`,
    data,
    name: 'хозяйство',
  });

export const deleteDivision = (divisionId) => () =>
  commonDelete({ url: `${urlsWrapper('divisions')}/${divisionId}` });

// selectors
export const getDivisionsSelector = createSelector(
  [(state) => state.divisions.list, (state) => state.divisions.isPending],
  (list, isPending) => ({
    list,
    isPending,
  })
);
