import { urls } from 'config';
import { sortListByImportantAndName } from 'utils';
import { convertDictionaryFilters } from 'utils/convertDictionaryFilters';
import {
  saveData,
  createCommonListConstants,
  createCommonListReducer,
  createCommonItemReducer,
  createCommonItemConstants,
  fetchDictionariesItemIfNotExist,
} from './utils';
import { commonGet } from './api';

const constants = createCommonListConstants('DISEASES');
const itemConstants = createCommonItemConstants('DISEASE_ITEM');

// reducer
export const diseases = createCommonListReducer(
  constants,
  sortListByImportantAndName
);
export const diseaseItem = createCommonItemReducer(itemConstants);

// actions
export function getDiseases(embedParam = 'none') {
  return saveData(
    `${urls().diseases}?embed=${embedParam}`,
    constants.SET_LIST,
    constants.SET_IS_PENDING
  );
}

function getDiseaseItem(itemId) {
  return commonGet({ url: `${urls().diseases}/${itemId}` });
}

const shouldFetchDiseases = (state) => state.diseases.list.length === 0;

export const fetchDiseasesIfNeeded = (embedParam) => (dispatch, getState) =>
  shouldFetchDiseases(getState())
    ? dispatch(getDiseases(embedParam))
    : Promise.resolve();

export function getDiseaseIdsBySearch(query = '', filters = {}) {
  return commonGet({
    url: `${urls().diseases}/search/ids`,
    params: {
      query,
      ...convertDictionaryFilters(filters),
    },
  });
}

export const fetchDiseaseItemIfNeeded = (itemId) =>
  fetchDictionariesItemIfNotExist({
    itemId,
    itemStateKey: 'diseaseItem',
    itemSubStateKey: null,
    itemConstants,
    getDictItem: getDiseaseItem,
  });
