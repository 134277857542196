import isEmpty from 'lodash.isempty';

export const convertDictionaryFilters = (filters) => {
  if (isEmpty(filters)) {
    return {};
  }

  return Object.fromEntries(
    Object.entries(filters)
      .filter(([, value]) => typeof value === 'boolean' || Boolean(value))
      .map(([key, value]) => [`filter[${key}]`, value])
  );
};
