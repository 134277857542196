import { urlsWrapper } from 'config';
import { serialize, sortListByImportantAndName } from 'utils';
import { createSelector } from 'reselect';
import {
  saveData,
  createCommonListConstants,
  createCommonListReducer,
} from './utils';
import { commonPost, commonDelete, commonPut, commonGet } from './api';

const constants = createCommonListConstants('FIELDS');

// reducer
export default createCommonListReducer(constants, sortListByImportantAndName);

// actions
export function getFields(params = { include: 'normal,bare' }) {
  return saveData(
    `${urlsWrapper('fields')}${params ? `?${serialize(params)}` : ''}`,
    constants.SET_LIST,
    constants.SET_IS_PENDING
  );
}

export const deleteField = (fieldId) => () =>
  commonDelete({ url: `${urlsWrapper('fields')}/${fieldId}` });

export const addField = (data) => () =>
  commonPost({ url: `${urlsWrapper('fields')}`, data, name: 'поле' });

export const updateField = (fieldId, data, isSync = true) => () =>
  commonPut({
    url: `${urlsWrapper('fields')}/${fieldId}${
      isSync ? '' : '?syncicons=false'
    }`,
    data,
    name: 'поле',
  });

export const updateSowings = (fieldId, data, errorMessage) => () =>
  commonPost({
    url: `${urlsWrapper('fields')}/${fieldId}/sowings`,
    data,
    name: 'посев',
    errorMessage,
  });

// selectors
export const getFieldsSelector = createSelector(
  [(state) => state.fields.list, (state) => state.fields.isPending],
  (list, isPending) => ({
    list,
    isPending,
  })
);

export function getField(fieldId) {
  return commonGet({
    url: `${urlsWrapper('fields')}/${fieldId}`,
  });
}
