import { urlsWrapper } from 'config';
import {
  saveData,
  createCommonItemConstants,
  createCommonItemReducer,
  RESET_TYPES,
} from './utils';
import { commonPut, commonGet } from './api';

const constants = createCommonItemConstants('PROFILE');

// reducer
export default createCommonItemReducer(constants);

// actions
export function getProfile() {
  return saveData(
    `${urlsWrapper('profile')}`,
    constants.SET_ITEM,
    constants.SET_IS_PENDING,
    RESET_TYPES.ITEM
  );
}

export const getBayerTsProfile = (id) => {
  return commonGet({
    url: `${urlsWrapper('bayerTsCustomers')}/${id}/profile`,
  });
};

export const updateProfile = (data) => () =>
  commonPut({
    url: `${urlsWrapper('profile')}`,
    data,
    name: 'карточку',
  });
