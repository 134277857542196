import { urls } from 'config';
import { sortListByImportantAndName } from 'utils';
import { createSelector } from 'reselect';
import {
  saveData,
  createCommonListConstants,
  createCommonListReducer,
} from './utils';

const constants = createCommonListConstants('TILLAGE_TYPES');

// reducer
export default createCommonListReducer(constants, sortListByImportantAndName);

// actions
export function getTillageTypes() {
  return saveData(
    urls().tillageTypes,
    constants.SET_LIST,
    constants.SET_IS_PENDING
  );
}

const shouldFetchTillageTypes = (state) => state.tillageTypes.list.length === 0;

export const fetchTillageTypesIfNeeded = () => (dispatch, getState) =>
  shouldFetchTillageTypes(getState())
    ? dispatch(getTillageTypes())
    : Promise.resolve();

// selectors
export const getTillageTypesSelector = createSelector(
  [(state) => state.tillageTypes.list, (state) => state.tillageTypes.isPending],
  (list, isPending) => ({
    list,
    isPending,
  })
);
