import { urls } from 'config';
import { combineReducers } from 'redux';
import { serialize } from 'utils';
import {
  saveData,
  createCommonItemConstants,
  createCommonListConstants,
  createCommonItemReducer,
  createCommonListReducer,
} from './utils';

const customerstatsConstants = createCommonItemConstants(
  'ADVISORS_ANALYTICS_CUSTOMERSTATS'
);
const cropsConstants = createCommonItemConstants('ADVISORS_ANALYTICS_CROPS');
const cropsScoutproblemsConstants = createCommonItemConstants(
  'ADVISORS_ANALYTICS_CROPS_SCOUTPROBLEMS'
);
const cropsByIdBreedsConstants = createCommonListConstants(
  'ADVISORS_ANALYTICS_CROPS_BREEDS'
);
const cropsByIdPesticidesConstants = createCommonListConstants(
  'ADVISORS_ANALYTICS_CROPS_PESTICIDES'
);
const cropsByIdFilledDataConstants = createCommonItemConstants(
  'ADVISORS_ANALYTICS_CROPS_FILLED_DATA'
);
const cropsYieldConstants = createCommonItemConstants(
  'ADVISORS_ANALYTICS_CROPS_YIELD'
);
const scoutsStatisticsConstants = createCommonItemConstants(
  'ADVISORS_SCOUTS_STATISTICS'
);
const scoutsStatisticsByUserIdConstants = createCommonItemConstants(
  'ADVISORS_SCOUTS_STATISTICS_BY_USER_ID'
);
const visitsConstants = createCommonItemConstants('ADVISORS_VISITS');

const customerstats = createCommonItemReducer(customerstatsConstants);
const crops = createCommonItemReducer(cropsConstants);
const cropsScoutproblems = createCommonItemReducer(cropsScoutproblemsConstants);
const cropsByIdBreeds = createCommonListReducer(cropsByIdBreedsConstants);
const cropsByIdPesticides = createCommonListReducer(
  cropsByIdPesticidesConstants
);
const cropsByIdFilledData = createCommonItemReducer(
  cropsByIdFilledDataConstants
);
const cropsYield = createCommonItemReducer(cropsYieldConstants);
const scoutsStatistics = createCommonItemReducer(scoutsStatisticsConstants);
const scoutsStatisticsByUserId = createCommonItemReducer(
  scoutsStatisticsByUserIdConstants
);
const visits = createCommonItemReducer(visitsConstants);

export default combineReducers({
  customerstats,
  crops,
  cropsScoutproblems,
  cropsByIdBreeds,
  cropsByIdPesticides,
  cropsByIdFilledData,
  scoutsStatistics,
  scoutsStatisticsByUserId,
  cropsYield,
  visits,
});

export const getAdvisorsAnalyticsCustomerstats = () =>
  saveData(
    urls().advisorsAnalyticsCustomerstats,
    customerstatsConstants.SET_ITEM,
    customerstatsConstants.SET_IS_PENDING
  );

export const getAdvisorsAnalyticsCrops = (params) =>
  saveData(
    `${urls().advisorsAnalyticsCrops}?${serialize(params)}`,
    cropsConstants.SET_ITEM,
    cropsConstants.SET_IS_PENDING
  );

export const getAdvisorsAnalyticsCropsScoutproblems = ({
  crop_id,
  ...params
}) =>
  saveData(
    `${urls().advisorsAnalyticsCrops}/${crop_id}/scoutproblems?${serialize(
      params
    )}`,
    cropsScoutproblemsConstants.SET_ITEM,
    cropsScoutproblemsConstants.SET_IS_PENDING
  );

export const getAdvisorsAnalyticsCropsByIdBreeds = (cropId, params) =>
  saveData(
    `${urls().advisorsAnalyticsCrops}/${cropId}/breeds?${serialize(params)}`,
    cropsByIdBreedsConstants.SET_LIST,
    cropsByIdBreedsConstants.SET_IS_PENDING
  );

export const getAdvisorsAnalyticsCropsByIdPesticides = (cropId, params) =>
  saveData(
    `${urls().advisorsAnalyticsCrops}/${cropId}/pesticides?${serialize(
      params
    )}`,
    cropsByIdPesticidesConstants.SET_LIST,
    cropsByIdPesticidesConstants.SET_IS_PENDING
  );

export const getAdvisorsAnalyticsCropsByIdFilledData = (cropId, params) =>
  saveData(
    `${urls().advisorsAnalyticsCrops}/${cropId}/filled_data?${serialize(
      params
    )}`,
    cropsByIdFilledDataConstants.SET_ITEM,
    cropsByIdFilledDataConstants.SET_IS_PENDING
  );

export const getAdvisorsAnalyticsScoutsStatistics = (params) =>
  saveData(
    `${urls().advisorsScoutsStatistic}?${serialize(params)}`,
    scoutsStatisticsConstants.SET_ITEM,
    scoutsStatisticsConstants.SET_IS_PENDING
  );

export const getAdvisorsAnalyticsScoutsStatisticsByUserId = (userId, params) =>
  saveData(
    `${urls().advisorsScoutsStatistic}/users/${userId}?${serialize(params)}`,
    scoutsStatisticsByUserIdConstants.SET_ITEM,
    scoutsStatisticsByUserIdConstants.SET_IS_PENDING
  );

export const getAdvisorsAnalyticsVisits = (params) =>
  saveData(
    `${urls().advisorsVisits}?${serialize(params)}`,
    visitsConstants.SET_ITEM,
    visitsConstants.SET_IS_PENDING
  );

export const getAdvisorsAnalyticsCropsYield = (cropId, params) =>
  saveData(
    `${urls().advisorsAnalyticsCrops}/${cropId}/yield?${serialize(params)}`,
    cropsYieldConstants.SET_ITEM,
    cropsYieldConstants.SET_IS_PENDING
  );
