import { urls } from 'config';
import { createAction } from 'redux-actions';
import { sortByDateKey } from 'utils';
import { isSceneAvailable } from 'utils/satelliteScenesFilter';
import {
  createCommonListConstants,
  createCommonListReducer,
  resetData,
  RESET_TYPES,
} from './utils';
import { commonGet } from './api';

const constants = createCommonListConstants('SAT_FIELD_SCENES');

// reducer
export default createCommonListReducer(constants);

// actions
const getSatFieldScenes = (fieldId) =>
  commonGet({
    url: `${urls().satFields}/${fieldId}/scenes`,
  });

export function resetSatFieldScenes() {
  return resetData(constants.SET_LIST, RESET_TYPES.LIST);
}

export const getSatFieldScenesList = (fieldId) => async (dispatch) => {
  dispatch(createAction(constants.SET_IS_PENDING)(true));
  try {
    const scenes = await getSatFieldScenes(fieldId);
    const filteredScenes = scenes?.data?.data
      ? scenes?.data?.data.filter(isSceneAvailable)
      : [];
    dispatch(
      createAction(constants.SET_LIST)(
        sortByDateKey(filteredScenes, 'date', 'desc')
      )
    );
  } catch (e) {
    dispatch(resetSatFieldScenes());
    throw e;
  } finally {
    dispatch(createAction(constants.SET_IS_PENDING)(false));
  }
};
