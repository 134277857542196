import { createAction } from 'redux-actions';
import { getItemById, setAdvisorCustomer } from 'utils';
import { createSelector } from 'reselect';
import { createCommonItemConstants, createCommonItemReducer } from './utils';

const constants = createCommonItemConstants('ADVISOR_CUSTOMER');

// reducer
export default createCommonItemReducer(constants);

// actions
export const setAdvisorCustomerToState = (data) => (dispatch, getState) => {
  let customer;
  if (typeof data === 'object' && data.id) {
    customer = data;
  } else {
    customer = getItemById(getState().customers.list, data) || { id: data };
  }
  setAdvisorCustomer(customer);
  dispatch(createAction(constants.SET_ITEM)(customer));
};

// selectors
export const getCurrentAdvisorCustomerSelector = createSelector(
  [(state) => state.currentAdvisorCustomer],
  (currentAdvisorCustomer) => currentAdvisorCustomer
);
