import { urlsWrapper } from 'config';
import { createSelector } from 'reselect';
import {
  createCommonItemConstants,
  createCommonItemReducer,
  RESET_TYPES,
  saveData,
} from './utils';
import { commonGet, commonPut } from './api';

const terrasoftTrialItem = createCommonItemConstants('TERRASOFT_TRIAL_ITEM');

export default createCommonItemReducer(terrasoftTrialItem);

export function getTerrasofttrial(fieldId) {
  return commonGet({
    url: `${urlsWrapper('fields')}/${fieldId}/terrasofttrial`,
  });
}

export function updateTerrasofttrial(fieldId) {
  return saveData(
    `${urlsWrapper('fields')}/${fieldId}/terrasofttrial`,
    terrasoftTrialItem.SET_ITEM,
    terrasoftTrialItem.SET_IS_PENDING,
    RESET_TYPES.ITEM
  );
}

export function putTerrasofttrial(fieldId, data) {
  return commonPut({
    url: `${urlsWrapper('fields')}/${fieldId}/terrasofttrial`,
    data,
  });
}

export const getTerrasoftTrialSelector = createSelector(
  [(state) => state.terrasoftTrial],
  (terrasoftTrial) => terrasoftTrial
);
