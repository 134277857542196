import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { getCropsSelector } from 'modules/crops';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import {
  getFilterDivisionsSelector,
  getFilterSeasonSelector,
  setFilterDivisions,
  setFilterSeasons,
} from 'modules/globalFilters';
import DefaultModal from 'components/common/modals/DefaultModal';
import message from 'antd/lib/message';
import {
  getNotificationsUnread,
  getUnreadNotificationsSelector,
  readNotification,
  readAllNotification,
} from 'modules/notifications';
import {
  getCurrentUser,
  getCurrentCustomer,
  getCurrentAdvisor,
  setLogout,
  getCurrentUserSelector,
} from 'modules/auth';
import {
  getCurrentAdvisorCustomerSelector,
  setAdvisorCustomerToState,
} from 'modules/currentAdvisorCustomer';
import { resetField } from 'modules/field';
import { env_bayer, env_externalservices, PUBLIC_URL } from 'env.js';
import {
  getUserId,
  getCustomerId,
  getAdvisorId,
  getYears,
  getAdvisorCustomerId,
  cleanLocalStorage,
} from 'utils';
import * as Sentry from '@sentry/browser';
import DivisionsDropdown from 'components/common/DivisionsDropdown';
import FieldSelectPanel from 'components/field/FieldInfo/FieldSelectPanel';
import { resetMapFilters } from 'modules/mapFilters';
import { getFieldsSelector } from 'modules/fields';
import { getDivisionsSelector } from 'modules/divisions';
import { getDivisionsGroupsSelector } from 'modules/divisionGroups';
import { getCustomersSelector } from 'modules/customers';

import Notifications from './Notifications';
import Support from './Support';

// TODO: make a logout function. Delete all stored data

class Header extends React.PureComponent {
  state = {
    showDefaultModal: false,
    isShowNotification: false,
  };

  componentDidMount() {
    this.props.getCurrentUser(getUserId());
    const customerId = getCustomerId();
    if (customerId) {
      this.props.getCurrentCustomer(customerId);
    }
    const advisorId = getAdvisorId();
    if (advisorId) {
      this.props.getCurrentAdvisor(advisorId);
    }
    if (env_bayer !== 'true') {
      this.props.getNotificationsUnread();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      (prevProps.currentUser || {}).id !== (this.props.currentUser || {}).id &&
      env_externalservices === 'true'
    ) {
      Sentry.configureScope((scope) => {
        scope.setUser(this.props.currentUser);
      });
    }
  }

  handleCloseClick = async (id) => {
    await this.props.readNotification(id);
    this.props.getNotificationsUnread();
  };

  handleReadAllNotification = async () => {
    try {
      await this.props.readAllNotification();
      this.props.getNotificationsUnread();
      this.toggleNotification(false);
    } catch (e) {
      message.error('Ошибка очистки уведомлений', 100);
    }
  };

  handleNotificationClick = ({
    fieldId,
    scoutId,
    customerId,
    notificationType,
    scoutPlanId,
  }) => {
    const { history, customers } = this.props;
    const examplePath = `/customer/${customerId}/fields/${fieldId}/scouts/${scoutId}`;
    const customer = customers.find((c) => c.id === customerId);

    if (notificationType === 4) {
      history.push(`/customer/${customerId}/recommendations`);
      return;
    }

    if (notificationType === 5) {
      history.push({
        pathname: `/customer/${customerId}/scouting/plans`,
        state: { scoutPlanId },
      });
      return;
    }

    if (history.location.pathname === examplePath) {
      return;
    }

    if (customer) {
      this.props.setAdvisorCustomerToState(customerId);
      if (
        !history.location.pathname.includes(
          `/customer/${customerId}/fields/${fieldId}/`
        )
      ) {
        this.props.resetField();
      }
    }
    history.push(`/customer/${customerId}/fields/${fieldId}/scouts/${scoutId}`);
  };

  showDefaultModal = () => {
    this.setState({ showDefaultModal: true });
  };

  hideDefaultModal = () => {
    this.setState({ showDefaultModal: false });
  };

  toggleNotification = (isShowNotification) => {
    this.setState({ isShowNotification });
  };

  render() {
    const {
      currentUser,
      crops,
      fields,
      history,
      globalFilterSeasons,
      unreadNotifications,
      divisions,
      divisionGroups,
      globalFilterDivisions,
      location,
      currentAdvisorCustomer,
    } = this.props;

    const years = getYears();
    const advisorCustomerId = getAdvisorCustomerId();
    const isAdvisorCustomerId = !!advisorCustomerId;
    const isCustomer = !!getCustomerId();
    const isAdvisorAnalyticsPath = location.pathname === '/advisor/analytics';

    return (
      <header className="page__header">
        {env_bayer === 'true' ? (
          <div
            className="logo pull-left"
            onClick={(e) => {
              e.preventDefault();
              history.push('/');
            }}
            role="button"
            tabIndex={0}
          >
            <img src={`${PUBLIC_URL}/icons/quantumterra_logo.svg`} alt="logo" />
          </div>
        ) : (
          <div
            className="logo logo_width pull-left"
            onClick={(e) => {
              e.preventDefault();
              history.push('/');
            }}
            role="button"
            tabIndex={0}
          >
            <img
              src={`${PUBLIC_URL}/images/agromon_logo_light.svg`}
              alt="logo"
            />
          </div>
        )}
        <nav className="pull-right">
          <ul className="nav nav-pills">
            {(isAdvisorCustomerId || isCustomer) &&
            !divisions.isPending &&
            divisions.list.length > 1 ? (
              <li className="header__user">{currentAdvisorCustomer.name}</li>
            ) : null}
            {(isAdvisorCustomerId || isCustomer) &&
              !divisions.isPending &&
              !divisionGroups.isPending && (
                <li className="divisions-dropdown__wrapper">
                  <DivisionsDropdown
                    divisions={divisions.list}
                    divisionGroups={divisionGroups.list}
                    value={globalFilterDivisions}
                    handleChange={this.props.setFilterDivisions}
                    className="header__select"
                  />
                </li>
              )}
            {(isAdvisorCustomerId || isCustomer || isAdvisorAnalyticsPath) && (
              <li>
                <DropdownButton
                  title={`Сезон ${globalFilterSeasons}`}
                  bsPrefix="header__select"
                  id="seasons-dropdown"
                  variant="none"
                >
                  {(years || [])
                    .filter((y) => y != globalFilterSeasons)
                    .map((year) => (
                      <Dropdown.Item
                        eventKey={year}
                        key={year}
                        onClick={() => {
                          this.props.setFilterSeasons(year);
                          localStorage.setItem('SEASON', year);
                        }}
                      >
                        Сезон {year}
                      </Dropdown.Item>
                    ))}
                </DropdownButton>
              </li>
            )}
            {(isAdvisorCustomerId || isCustomer) && (
              <li className="header__button" onClick={this.showDefaultModal}>
                К полю &rsaquo;
              </li>
            )}
            {env_bayer !== 'true' && (
              <li>
                <DropdownButton
                  variant="none"
                  title={
                    <div className="notification-badge">
                      <i className="fa fa-bell" />
                      <span className="notification-badge__amount">
                        {unreadNotifications.list?.length ?? 0}
                      </span>
                    </div>
                  }
                  show={this.state.isShowNotification}
                  onToggle={this.toggleNotification}
                  disabled={!unreadNotifications.list?.length}
                  bsPrefix="header__select header__link header__select_no-caret"
                  id="notifications-dropdown"
                >
                  <Notifications
                    notifications={unreadNotifications.list || []}
                    handleReadAllNotification={this.handleReadAllNotification}
                    handleNotificationClick={this.handleNotificationClick}
                    handleCloseClick={this.handleCloseClick}
                  />
                </DropdownButton>
              </li>
            )}
            <li>
              <DropdownButton
                variant="none"
                title={<i className="fa fa-question" />}
                bsPrefix="header__select header__link header__select_no-caret"
                id="support-dropdown"
              >
                <Support />
              </DropdownButton>
            </li>
            {Object.keys(currentUser || {}).length && env_bayer !== 'true' ? (
              <li className="header__user">
                {`${currentUser.last_name} ${currentUser.first_name}`}
              </li>
            ) : null}
            {env_bayer !== 'true' && (
              <li>
                <div
                  onClick={(e) => {
                    e.preventDefault();
                    cleanLocalStorage();
                    this.props.setFilterDivisions();
                    this.props.resetMapFilters();
                    this.props.setLogout();
                    history.push('/login');
                  }}
                  className="header__link"
                  role="button"
                  tabIndex={0}
                >
                  <i className="fas fa-sign-out-alt" />
                </div>
              </li>
            )}
          </ul>
        </nav>
        <DefaultModal
          title="Выберите поле для перехода"
          body={
            <FieldSelectPanel
              fields={fields}
              crops={crops}
              divisions={divisions}
              globalFilterSeasons={globalFilterSeasons}
              globalFilterDivisions={globalFilterDivisions}
              history={history}
              handleClose={this.hideDefaultModal}
              customerId={advisorCustomerId}
            />
          }
          show={this.state.showDefaultModal}
          close={this.hideDefaultModal}
        />
      </header>
    );
  }
}

const header = connect(
  (state) => ({
    currentUser: getCurrentUserSelector(state),
    fields: getFieldsSelector(state),
    crops: getCropsSelector(state),
    divisions: getDivisionsSelector(state),
    divisionGroups: getDivisionsGroupsSelector(state),
    globalFilterDivisions: getFilterDivisionsSelector(state),
    globalFilterSeasons: getFilterSeasonSelector(state),
    unreadNotifications: getUnreadNotificationsSelector(state),
    customers: getCustomersSelector(state).list,
    currentAdvisorCustomer: getCurrentAdvisorCustomerSelector(state).item,
  }),
  {
    getCurrentUser,
    getCurrentCustomer,
    getCurrentAdvisor,
    setLogout,
    resetMapFilters,
    setFilterDivisions,
    setFilterSeasons,
    getNotificationsUnread,
    readNotification,
    readAllNotification,
    setAdvisorCustomerToState,
    resetField,
  }
)(Header);

export default withRouter(header);
