import { urls } from 'config';
import { sortListByImportantAndName } from 'utils';
import {
  saveData,
  createCommonListConstants,
  createCommonListReducer,
} from './utils';

const constants = createCommonListConstants('SOIL_TYPES');

// reducer
export default createCommonListReducer(constants, sortListByImportantAndName);

// actions
export function getSoilTypes() {
  return saveData(
    urls().soilTypes,
    constants.SET_LIST,
    constants.SET_IS_PENDING
  );
}

const shouldFetchSoilTypes = (state) => state.soilTypes.list.length === 0;

export const fetchSoilTypesIfNeeded = () => (dispatch, getState) =>
  shouldFetchSoilTypes(getState())
    ? dispatch(getSoilTypes())
    : Promise.resolve();
