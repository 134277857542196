import { combineReducers } from 'redux';
import { urls } from 'config';
import { createSelector } from 'reselect';
import {
  saveData,
  createCommonListConstants,
  createCommonListReducer,
} from './utils';
import { commonPost } from './api';

const unread = createCommonListConstants('NOTIFICATIONS_UNREAD');

// reducer
export default combineReducers({
  unread: createCommonListReducer(unread),
});

// actions
export function getNotificationsUnread() {
  return saveData(
    `${urls().notifications}/unread`,
    unread.SET_LIST,
    unread.SET_IS_PENDING
  );
}

export const readNotification = (notificationId) => () =>
  commonPost({
    url: `${urls().notifications}/${notificationId}/read`,
    name: 'уведомление',
  });

export const readAllNotification = () => () =>
  commonPost({
    url: `${urls().notificationsReadAll}`,
    name: 'уведомление',
  });

// selectors
export const getUnreadNotificationsSelector = createSelector(
  [
    (state) => state.notifications.unread.list,
    (state) => state.notifications.unread.isPending,
  ],
  (list, isPending) => ({
    list,
    isPending,
  })
);
