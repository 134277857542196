import { urls } from 'config';
import { createAction } from 'redux-actions';
import { createSelector } from 'reselect';
import { commonGet } from 'modules/api';
import { createCommonItemConstants, createCommonItemReducer } from './utils';
import {
  getSatProducts,
  POLL_INTERVAL,
  setProcess,
  stopPollingSatProducts,
} from './satProductsChecking';

const satProductsByDivisionConstant = createCommonItemConstants(
  'SAT_PRODUCTS_BY_DIVISION'
);

// reducer
export default createCommonItemReducer(satProductsByDivisionConstant);

const setIsPending = createAction(satProductsByDivisionConstant.SET_IS_PENDING);
const setItem = createAction(satProductsByDivisionConstant.SET_ITEM);

// actions
export const stopPollingSatProductsByDivision = () =>
  stopPollingSatProducts(setIsPending);

export const getSatProductsByDivision = (divisionId, activeScene) =>
  getSatProducts(
    `${urls().satDivisions}/${divisionId}/products/${
      activeScene?.source_type
    }/${activeScene?.date}`,
    setItem,
    setIsPending
  );

export const getReliefByDivision = (divisionsId) => {
  const url = `${urls().satDivisionRelief}/${divisionsId}`;
  return commonGet({
    url,
  });
};

export const getSatProductsByDivisions = (divisionsId, activeScene) => async (
  dispatch
) => {
  try {
    dispatch(setIsPending(true));
    dispatch(setItem({}));
    dispatch(setProcess(null));

    const response = await Promise.all(
      (divisionsId || []).map(
        (divisionId) =>
          new Promise((res) => {
            const url = `${urls().satDivisions}/${divisionId}/products/${
              activeScene.source_type
            }/${activeScene.date}`;
            const timeoutCb = async () => {
              try {
                const { data, status } = await commonGet({
                  url,
                });
                if (status !== 204) {
                  res({ fields: data?.fields ?? [] });
                } else {
                  setTimeout(timeoutCb, POLL_INTERVAL);
                }
              } catch (error) {
                res({ fields: [] });
              }
            };
            setTimeout(timeoutCb, POLL_INTERVAL);
          })
      )
    );

    const data = response.reduce(
      (result, item) => {
        // eslint-disable-next-line no-param-reassign
        result.fields = result.fields.concat(item.fields || []);
        return result;
      },
      { fields: [] }
    );

    dispatch(setItem(data));
    dispatch(stopPollingSatProducts(setIsPending));
  } catch (e) {
    dispatch(stopPollingSatProducts(setIsPending));
  }
};

export const TITLE = {
  1: 'Очень однородное',
  2: 'Однородное',
  3: 'Средне однородное',
  4: 'Неоднородное',
  5: 'Очень неоднородное',
};

// selectors
export const getSatProductsByDivisionSelector = createSelector(
  [
    (state) => state.satProductsByDivision.item,
    (state) => state.satProductsByDivision.isPending,
  ],
  (item, isPending) => ({
    item,
    isPending,
  })
);
