import React from 'react';
import Modal from 'react-bootstrap/Modal';

const DefaultModal = ({ title, body, show, close }) => (
  <Modal show={show} onHide={close}>
    <Modal.Header closeButton>
      <Modal.Title>{title}</Modal.Title>
    </Modal.Header>
    <Modal.Body>{body}</Modal.Body>
  </Modal>
);

export default React.memo(DefaultModal);
