import * as Sentry from '@sentry/browser';
import { NODE_ENV, env_externalservices } from 'env';

// send error to Sentry for non development environment and show them in console otherwise
export default (err) => {
  if (NODE_ENV !== 'production') {
    // eslint-disable-next-line no-console
    console.error(err);
  } else {
    // Sentry can only parse Error instances
    let error = err;
    if (typeof error === 'string' || error instanceof String) {
      error = new Error(error);
    } else if (typeof error === 'object' && error !== null) {
      error = new Error(error.message);
    }
    if (env_externalservices === 'true') {
      Sentry.captureException(error);
    }
  }
};
