import { urls } from 'config';
import {
  saveData,
  createCommonListConstants,
  createCommonListReducer,
} from './utils';

const constants = createCommonListConstants('NUTRITION_PROBLEMS');

// reducer
export default createCommonListReducer(constants);

// actions
export function getNutritionProblems() {
  return saveData(
    urls().nutritionproblems,
    constants.SET_LIST,
    constants.SET_IS_PENDING
  );
}

const shouldFetchNutritionProblems = (state) =>
  state.nutritionproblems.list.length === 0;

export const fetchNutritionProblemsIfNeeded = () => (dispatch, getState) =>
  shouldFetchNutritionProblems(getState())
    ? dispatch(getNutritionProblems())
    : Promise.resolve();
