import { urls } from 'config';
import { createSelector } from 'reselect';
import {
  saveData,
  createCommonListConstants,
  createCommonListReducer,
} from './utils';

const constants = createCommonListConstants('CROP_DEV_STAGES');

// reducer
export default createCommonListReducer(constants);

// actions

const shouldFetch = (state) => state.cropDevStages?.list?.length === 0;

export function getStages() {
  return saveData(
    urls().cropDevStages,
    constants.SET_LIST,
    constants.SET_IS_PENDING
  );
}

export const fetchStagesIfNeeded = (embedParam) => (dispatch, getState) =>
  shouldFetch(getState()) ? dispatch(getStages(embedParam)) : Promise.resolve();

// selectors
export const getCropDevStagesSelector = createSelector(
  [
    (state) => state.cropDevStages.list,
    (state) => state.cropDevStages.isPending,
  ],
  (list, isPending) => ({
    list,
    isPending,
  })
);
