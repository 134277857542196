import { env_bayer, PUBLIC_URL } from 'env';

const addTitle = () => {
  document.title = env_bayer === 'true' ? 'QuantumTerra' : 'АгроМон';
};

const addFavicon = () => {
  let link = document.getElementById('favicon');
  if (!link) {
    link = document.createElement('link');
    link.rel = 'shortcut icon';
    link.id = 'favicon';
    document.getElementsByTagName('head')[0].appendChild(link);
  }
  link.href = `${PUBLIC_URL}/${
    process.env.env_bayer === 'true'
      ? 'favicon_quantumterra.png'
      : 'favicon.png'
  }`;
};

export const addPageHeadSettings = () => {
  addTitle();
  addFavicon();
};
