import { urlsWrapper } from 'config';
import { getContentDisposition } from 'utils';
import download from 'downloadjs';
import {
  saveData,
  createCommonListConstants,
  createCommonListReducer,
} from './utils';
import { commonPut, commonPost, commonDelete, commonGet } from './api';

const constants = createCommonListConstants('DOCUMENTS');

// reducer
export default createCommonListReducer(constants);

// actions
export function getDocumentsList() {
  return saveData(
    urlsWrapper('documents'),
    constants.SET_LIST,
    constants.SET_IS_PENDING
  );
}

export const addDocumentMetadata = (data) => () =>
  commonPost({
    url: `${urlsWrapper('documents')}`,
    data,
    name: 'данные документа',
  });

export const addDocument = (documentId, imageBlob) => () => {
  const data = new FormData();
  data.append('document', imageBlob);
  return commonPut({
    url: `${urlsWrapper('documents')}/${documentId}/file`,
    data,
    errorMessage: 'Не удалось загрузить документ',
  });
};

export const deleteDocument = (documentId) => () =>
  commonDelete({ url: `${urlsWrapper('documents')}/${documentId}` });

export const downloadDocument = (documentId, mimeType) => async () => {
  const url = `${urlsWrapper('documents')}/${documentId}/file`;
  const response = await commonGet({ url, responseType: 'blob' });
  const fileName = getContentDisposition(response.headers);
  download(response.data, fileName, mimeType);
  return response;
};
