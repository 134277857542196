import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const renderTooltip = ({ text }) => (
  <Tooltip id="button-tooltip">
    <div
      className="align-left tooltip-popup"
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{
        __html: text,
      }}
    />
  </Tooltip>
);

const Tooltips = ({ content, placement }) => (
  <OverlayTrigger
    placement={placement || 'top'}
    overlay={renderTooltip({ text: content })}
  >
    <i className="tooltips far fa-question-circle" />
  </OverlayTrigger>
);

export default Tooltips;
