import { urls } from 'config';
import {
  saveData,
  createCommonListConstants,
  createCommonListReducer,
} from './utils';

const constants = createCommonListConstants('WEATHER_PROBLEMS');

// reducer
export default createCommonListReducer(constants);

// actions
export function getWeatherProblems() {
  return saveData(
    urls().weatherproblems,
    constants.SET_LIST,
    constants.SET_IS_PENDING
  );
}

const shouldFetchWeatherProblems = (state) =>
  state.weatherproblems.list.length === 0;

export const fetchWeatherProblemsIfNeeded = () => (dispatch, getState) =>
  shouldFetchWeatherProblems(getState())
    ? dispatch(getWeatherProblems())
    : Promise.resolve();
