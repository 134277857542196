import { constants as globalFilter } from 'modules/globalFilters';

export const scoutFilter = {
  SET_FILTERS: 'SCOUT_FILTERS_SET_FILTERS',
};

// reducer
const defaultState = {
  alarm: false,
  user: null,
  filterDivisionsSelected: [0],
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case scoutFilter.SET_FILTERS:
      return { ...state, ...action.payload };
    case globalFilter.SET_FILTER_DIVISIONS:
      return { ...state, filterDivisionsSelected: action.filterState };
    default:
      return state;
  }
};

// actions
export function setScoutFilters(payload) {
  return {
    type: scoutFilter.SET_FILTERS,
    payload,
  };
}
