import { urls } from 'config';
import { sortListByImportantAndName } from 'utils';
import { createSelector } from 'reselect';
import {
  saveData,
  createCommonListConstants,
  createCommonListReducer,
} from './utils';

const constants = createCommonListConstants('WORK_TYPES');

// reducer
export default createCommonListReducer(constants, sortListByImportantAndName);

// actions
export function getWorkTypes() {
  return saveData(
    urls().workTypes,
    constants.SET_LIST,
    constants.SET_IS_PENDING
  );
}

const shouldFetchWorkTypes = (state) => state.workTypes.list.length === 0;

export const fetchWorkTypesIfNeeded = () => (dispatch, getState) =>
  shouldFetchWorkTypes(getState())
    ? dispatch(getWorkTypes())
    : Promise.resolve();

export const getWorkTypesSelector = createSelector(
  [(state) => state.workTypes],
  (list) => list
);
