import { urls } from 'config';
import {
  saveData,
  createCommonListConstants,
  createCommonListReducer,
} from './utils';

const constants = createCommonListConstants('WORK_PROBLEMS');

// reducer
export default createCommonListReducer(constants);

// actions
export function getWorkProblems() {
  return saveData(
    urls().workproblems,
    constants.SET_LIST,
    constants.SET_IS_PENDING
  );
}

const shouldFetchWorkProblems = (state) => state.workproblems.list.length === 0;

export const fetchWorkProblemsIfNeeded = () => (dispatch, getState) =>
  shouldFetchWorkProblems(getState())
    ? dispatch(getWorkProblems())
    : Promise.resolve();
