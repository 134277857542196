import { plural } from 'utils';

const getFieldPlotInfo = (sowing) => {
  const count = sowing?.plots_count || 0;
  return !count
    ? ''
    : `Опыты - ${count} ${plural(count, ['участок', 'участка', 'участков'])}`;
};

export default getFieldPlotInfo;
