import { urls, urlsWrapper } from 'config';
import { createSelector } from 'reselect';
import { serialize } from 'utils';
import {
  saveData,
  createCommonListConstants,
  createCommonListReducer,
} from './utils';
import { commonDelete, commonGet, commonPost, commonPut } from './api';

const constants = createCommonListConstants('CUSTOMERS');

// reducer
export default createCommonListReducer(constants);

// actions
export function getCustomersList() {
  return saveData(
    urls().customers,
    constants.SET_LIST,
    constants.SET_IS_PENDING
  );
}

export const addCustomer = (data) => () =>
  commonPost({
    url: `${urls().customers}`,
    data,
    name: 'клиента',
  });

export const getCustomerScoutPlans = () =>
  commonGet({
    url: `${urlsWrapper('customersScoutPlans')}`,
  });
export const getCustomerScoutRecommendations = (params) =>
  commonGet({
    url: `${urlsWrapper('recommendations')}?${serialize(params)}`,
  });
export const getCustomerScoutPlan = (id) =>
  commonGet({
    url: `${urlsWrapper('customersScoutPlans')}/${id}`,
  });
export const deleteCustomerScoutPlan = (id) =>
  commonDelete({
    url: `${urlsWrapper('customersScoutPlans')}/${id}`,
  });

export const addCustomerScoutPlan = (data) => () =>
  commonPost({
    url: `${urlsWrapper('customersScoutPlans')}`,
    data,
    name: 'план',
  });

export const editCustomerScoutPlan = (data, id) => () =>
  commonPut({
    url: `${urlsWrapper('customersScoutPlans')}/${id}`,
    data,
    name: 'план',
  });

// selectors
export const getCustomersSelector = createSelector(
  [(state) => state.customers.list, (state) => state.customers.isPending],
  (list, isPending) => ({
    list,
    isPending,
  })
);
