import bowser from 'bowser';
import React, { useState, useEffect } from 'react';
import { env_type } from 'env';
import { BrowserSupportBlank } from './BrowserSupportBlank';

export const BrowserSupport = ({ children }) => {
  const [isSupported, setIsSupported] = useState(true);

  useEffect(() => {
    if (!['production', 'staging'].includes(env_type)) {
      return;
    }
    const browser = bowser.getParser(window.navigator.userAgent);
    const isSupportedValid = browser.satisfies({
      // Specify the minimum required version of the browser here
      chrome: '>=50',
      firefox: '>=50',
      yandex: '>=20',
      electron: '>=10',
    });
    setIsSupported(isSupportedValid);
  }, []);

  if (!isSupported) {
    // Show the error page
    return <BrowserSupportBlank />;
  }

  return children;
};
