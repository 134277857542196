import { combineReducers } from 'redux';
import { urls } from 'config';
import { sortListByImportantAndName } from 'utils';
import { createSelector } from 'reselect';
import { convertDictionaryFilters } from 'utils/convertDictionaryFilters';
import {
  saveData,
  createCommonListConstants,
  createCommonListReducer,
  createCommonItemReducer,
  createCommonItemConstants,
  fetchDictionariesItemIfNotExist,
} from './utils';
import { commonGet } from './api';

const fertilizers = createCommonListConstants('FERTILIZERS');
const fertilizerItem = createCommonItemConstants('FERTILIZER_ITEM');
const vendors = createCommonListConstants('FERTILIZER_VENDORS');

// reducer
export default combineReducers({
  fertilizers: createCommonListReducer(fertilizers, sortListByImportantAndName),
  fertilizerItem: createCommonItemReducer(fertilizerItem),
  vendors: createCommonListReducer(vendors),
});

// actions
export function getFertilizers(embedParam = 'none') {
  return saveData(
    `${urls().fertilizers}?embed=${embedParam}`,
    fertilizers.SET_LIST,
    fertilizers.SET_IS_PENDING
  );
}

function getFertilizerItem(itemId) {
  return commonGet({ url: `${urls().fertilizers}/${itemId}` });
}

export function getFertilizerVendors() {
  return saveData(
    urls().fertilizerVendors,
    vendors.SET_LIST,
    vendors.SET_IS_PENDING
  );
}

const shouldFetchFertilizers = (state) =>
  state.fertilizers.fertilizers.list.length === 0;

export function getFertilizersIdsBySearch(query = '', filters) {
  return commonGet({
    url: `${urls().fertilizers}/search/ids`,
    params: {
      query: query || undefined,
      ...convertDictionaryFilters(filters),
    },
  });
}

export const fetchFertilizersIfNeeded = (embedParam) => (dispatch, getState) =>
  shouldFetchFertilizers(getState())
    ? dispatch(getFertilizers(embedParam))
    : Promise.resolve();

const shouldFetchFertilizerVendors = (state) =>
  state.fertilizers.vendors.list.length === 0;

export const fetchFertilizerVendorsIfNeeded = () => (dispatch, getState) =>
  shouldFetchFertilizerVendors(getState())
    ? dispatch(getFertilizerVendors())
    : Promise.resolve();

export const fetchFertilizerItemIfNeeded = (itemId) =>
  fetchDictionariesItemIfNotExist({
    itemId,
    itemStateKey: 'fertilizers',
    itemSubStateKey: 'fertilizerItem',
    itemConstants: fertilizerItem,
    getDictItem: getFertilizerItem,
  });

export const getFertilizersSelector = createSelector(
  [(state) => state.fertilizers?.fertilizers],
  (list) => list
);
