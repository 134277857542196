import { createAction } from 'redux-actions';
import { createSelector } from 'reselect';
import { createCommonItemConstants, createCommonItemReducer } from './utils';
import { commonGet } from './api';

export const POLL_INTERVAL = 1000;

const satProductsCheckingConstant = createCommonItemConstants(
  'SAT_PRODUCTS_CHECKING_PROCESS'
);

// reducer
export default createCommonItemReducer(satProductsCheckingConstant);

export const setProcess = createAction(satProductsCheckingConstant.SET_ITEM);

// actions
let interval;
export const stopPollingSatProducts = (
  setIsPending,
  intervalResult = { interval }
) => (dispatch) => {
  clearTimeout(intervalResult.interval);
  dispatch(setIsPending(false));
  dispatch(setProcess(null));
};

export const getSatProducts = (
  url,
  setItem,
  setIsPending,
  intervalResult = { interval }
) => async (dispatch) => {
  clearTimeout(intervalResult.interval);
  dispatch(setIsPending(true));
  dispatch(setItem({}));
  dispatch(setProcess(null));

  const intervalStatusCheck = async () => {
    try {
      const { data, status, headers } = await commonGet({
        url,
      }).catch(() => {});
      if (status !== 204) {
        if (data) {
          dispatch(setItem(data));
        }
        dispatch(stopPollingSatProducts(setIsPending, intervalResult));
      } else if (headers['x-process-progress']) {
        dispatch(setProcess(headers['x-process-progress']));
        // eslint-disable-next-line no-param-reassign
        intervalResult.interval = setTimeout(
          intervalStatusCheck,
          POLL_INTERVAL
        );
      }
    } catch (error) {
      dispatch(stopPollingSatProducts(setIsPending, intervalResult));
    }
  };

  // eslint-disable-next-line no-param-reassign
  intervalResult.interval = setTimeout(intervalStatusCheck, POLL_INTERVAL);
};

// selectors
export const getSatProductsCheckingSelector = createSelector(
  [
    (state) => state.satProductsChecking.item,
    (state) => state.satProductsChecking.isPending,
  ],
  (item, isPending) => ({
    item,
    isPending,
  })
);
