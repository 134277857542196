/* eslint-disable max-classes-per-file */
import mixpanel from 'mixpanel-browser';
import { env_externalservices } from 'env';

const globalProperties = {};

class Timer {
  timerEvent = '';

  startProps = {};

  constructor(event, startProps = {}) {
    this.timerEvent = event;
    this.startProps = startProps;
    if (env_externalservices === 'true') {
      try {
        mixpanel.time_event(event);
      } catch (e) {
        /* eslint-disable-next-line no-console */
        console.warn(`Unable to track ${this.timerEvent}`);
      }
    }
  }

  stop(properties = {}, callback) {
    const combined = { ...globalProperties, ...this.startProps, ...properties };
    // eslint-disable-next-line no-use-before-define
    if (Metrics.DEBUG) {
      // eslint-disable-next-line no-console
      console.log(`Timer.stop(${this.timerEvent})`, combined);
    }
    if (env_externalservices === 'true') {
      try {
        mixpanel.track(this.timerEvent, combined, callback);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.warn(`Unable to track ${this.timerEvent}`);
      }
    }
  }
}

class Metrics {
  static DEBUG = false;

  className;

  properties = {};

  static init({ mixpanelToken, debug, ...properties }) {
    if (!mixpanelToken) {
      throw new Error('Missing mixpanelToken parameter');
    }
    mixpanel.init(mixpanelToken);
    Metrics.DEBUG = debug !== undefined && !!debug;

    Metrics.props(properties);
  }

  static props(properties) {
    Object.keys(properties)
      .filter((key) => key !== 'mixpanelToken' && key !== 'debug')
      .forEach((key) => {
        const value = properties[key] || null;
        switch (typeof value) {
          case 'string':
          case 'number':
          case 'boolean':
          case 'undefined':
            globalProperties[key] = value;
            break;
          default:
            if (value === null) {
              // typeof null is 'object', so it needs to be special-cased.
              globalProperties[key] = value;
            } else {
              /* eslint-disable-next-line no-console */
              console.warn(
                `Not adding { "${key}": "${
                  Metrics.DEBUG ? value : '<value>'
                }" } to the metrics. Only simple data types are supported.`
              );
              delete globalProperties[key];
              break;
            }
        }
      });
  }

  static identify(uid) {
    mixpanel.identify(uid);
  }

  static people(info) {
    mixpanel.people.set(info);
  }

  static create(className, properties = {}) {
    return new Metrics(className, properties, false);
  }

  static stop(possibleTimer, properties = {}, callback) {
    if (Metrics.DEBUG || (possibleTimer && possibleTimer.stop)) {
      possibleTimer.stop(properties, callback);
    }
  }

  constructor(className, properties = {}, deprecated = true) {
    if (deprecated === true) {
      /* eslint-disable-next-line no-console */
      console.warn(
        'new Metrics(..) has been deprecated; please use Metrics.create(..) instead.'
      );
    }

    this.className = String(className.name || className);
    this.properties = { ...properties };
  }

  start(name, properties = {}) {
    return new Timer(`${this.className}.${name}`, {
      ...this.properties,
      ...properties,
    });
  }

  track(name, properties = {}, callback) {
    const combined = { ...globalProperties, ...this.properties, ...properties };
    if (Metrics.DEBUG) {
      /* eslint-disable-next-line no-console */
      console.log(`Metrics.track(${this.className}.${name})`, combined);
    }
    if (env_externalservices === 'true') {
      try {
        mixpanel.track(`${this.className}.${name}`, combined, callback);
      } catch (e) {
        /* eslint-disable-next-line no-console */
        console.warn(`Unable to track ${this.className}.${name}`);
      }
    }
  }
}

export default Metrics;
