import { urlsWrapper } from 'config';
import { createSelector } from 'reselect';
import { commonGet } from './api';
import {
  saveData,
  createCommonItemConstants,
  createCommonItemReducer,
  resetData,
  RESET_TYPES,
} from './utils';

const constants = createCommonItemConstants('FIELD');

// reducer
export default createCommonItemReducer(constants);

// actions
export function getField(fieldId) {
  return saveData(
    `${urlsWrapper('fields')}/${fieldId}`,
    constants.SET_ITEM,
    constants.SET_IS_PENDING
  );
}

export function getGeoData() {
  return commonGet({
    url: urlsWrapper('geoTrackers'),
  });
}

export function getMachineData(sourceId, serial) {
  return commonGet({
    url: `${urlsWrapper('geoTrackersMachines')}/${sourceId}/${serial}`,
  });
}

export function resetField() {
  return resetData(constants.SET_ITEM, RESET_TYPES.ITEM);
}

// selectors
export const getFieldSelector = createSelector(
  [(state) => state.field.item, (state) => state.field.isPending],
  (item, isPending) => ({
    item,
    isPending,
  })
);
