export const env_domain =
  process.env.env_domain || 'https://backend.staging.agromon.ru';
export const sat_domain =
  process.env.sat_domain || 'https://satreceiver.app.agromon.ru';
export const geoprocessor_domain =
  process.env.geoprocessor_domain || 'https://geoprocessor.staging.agromon.ru';
export const soilreceiver_domain =
  process.env.soilreceiver_domain || 'https://soilreceiver.staging.agromon.ru';
export const env_type = process.env.env_type || 'staging';
export const { env_bayer } = process.env;
export const env_externalservices =
  process.env.env_externalservices || env_bayer === 'true' ? 'false' : 'true';
export const PUBLIC_URL = process.env.PUBLIC_URL
  ? `${process.env.PUBLIC_URL}`
  : '';
export const { env_commit } = process.env;
export const { NODE_ENV } = process.env;
// Use mocked responses
export const env_mock = process.env.env_mock || false;
export const rosreestr_domain = 'https://pkk.rosreestr.ru';
