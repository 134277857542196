import { urlsWrapper } from 'config';
import { createSelector } from 'reselect';
import {
  saveData,
  createCommonListConstants,
  createCommonListReducer,
} from './utils';
import { commonDelete, commonPost } from './api';

const constants = createCommonListConstants('MARKERS');

// reducer
export default createCommonListReducer(constants);

// actions
export function getMarkers() {
  return saveData(
    `${urlsWrapper('markers')}`,
    constants.SET_LIST,
    constants.SET_IS_PENDING
  );
}

export const deleteMarker = (markerId) => () =>
  commonDelete({ url: `${urlsWrapper('markers')}/${markerId}` });

export const addMarker = (data) => () =>
  commonPost({ url: `${urlsWrapper('markers')}`, data });

// selector
export const getMarkerSelector = createSelector(
  [(state) => state.markers.list],
  (markers) => markers
);
