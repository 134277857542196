import { createSelector } from 'reselect';
import { urlsWrapper } from 'config';
import { commonDelete, commonPost, commonPut } from './api';
import {
  createCommonListConstants,
  createCommonListReducer,
  saveData,
} from './utils';

const mechanizers = createCommonListConstants('MECHANIZERS');

export default createCommonListReducer(mechanizers);

export function getMechanizers() {
  return saveData(
    `${urlsWrapper('mechanizers')}`,
    mechanizers.SET_LIST,
    mechanizers.SET_IS_PENDING
  );
}

const shouldFetchMechanizers = (state) => !state.mechanizers.list.length;

export const fetchMechanizersIfNeeded = () => (dispatch, getState) => {
  const state = getState();
  return shouldFetchMechanizers(state)
    ? dispatch(getMechanizers())
    : Promise.resolve();
};

export const addMechanizers = (data) =>
  commonPost({
    url: `${urlsWrapper('mechanizers')}`,
    data,
  });

export const deleteMechanizers = (id) =>
  commonDelete({
    url: `${urlsWrapper('mechanizers')}/${id}`,
  });

export const updateMechanizers = (data, id) =>
  commonPut({
    url: `${urlsWrapper('mechanizers')}/${id}`,
    data,
  });

export const getMechanizersSelector = createSelector(
  [(state) => state.mechanizers.list, (state) => state.mechanizers.isPending],
  (list, isPending) => ({
    list,
    isPending,
  })
);

export const getMechanizerName = (mechanizer) => {
  if (!mechanizer) return '';
  const { last_name: lastName, first_name: firstName } = mechanizer || {};
  return [lastName, firstName].filter((v) => v).join(' ');
};

export const errorCodes = {
  WorkrecordMachineryExists: 'WorkrecordMachineryExists',
};

export const getErrorMessage = (e) => {
  if (e?.code === errorCodes.WorkrecordMachineryExists) {
    return 'Удалить механизатора нельзя, поскольку он указан в работах';
  }
  return 'Ошибка удаления механизатора';
};
