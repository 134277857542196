import { urlsWrapper } from 'config';
import { createSelector } from 'reselect';
import {
  saveData,
  RESET_TYPES,
  createCommonItemReducer,
  createCommonItemConstants,
} from './utils';

const constants = createCommonItemConstants('USER_INFO');

// reducer
export default createCommonItemReducer(constants);

// actions
export function getUserInfo() {
  return saveData(
    urlsWrapper('userInfo'),
    constants.SET_ITEM,
    constants.SET_IS_PENDING,
    RESET_TYPES.ITEM
  );
}

// selector
export const getUserInfoSelector = createSelector(
  [(state) => state.userInfo.item],
  (userInfo) => userInfo
);
