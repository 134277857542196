import { urls, urlsWrapper } from 'config';
import isEqual from 'lodash.isequal';
import { createSelector } from 'reselect';
import { commonGet, commonPost } from './api';

const getDeffaultStateMapStyle = () => +localStorage.getItem('mapStyle') || 0;

export const mapbox = {
  SET_TOKEN: 'MAPBOX_SET_TOKEN',
  SET_FIELDS_GEOJSON: 'MAPBOX_SET_FIELDS_GEOJSON',
  INSERT_FIELDS_GEOJSON: 'MAPBOX_INSERT_FIELDS_GEOJSON',
  SET_CURRENT_ITEM: 'MAPBOX_SET_CURRENT_ITEM',
  SET_ROUTE: 'MAPBOX_SET_ROUTE',
  SET_STYLE: 'MAPBOX_SET_STYLE',
  SET_BOUNDS: 'MAPBOX_SET_BOUNDS',
  SET_TOKEN_IS_PENDING: 'MAPBOX_SET_TOKEN_IS_PENDING',
  SET_FIELDS_GEOJSON_IS_PENDING: 'MAPBOX_SET_FIELDS_GEOJSON_IS_PENDING',
};

// reducer
const defaultState = {
  token: null,
  fieldsGeojson: {
    type: 'FeatureCollection',
    bbox: [],
    features: [],
  },
  route: null,
  currentItem: null,
  mapStyle: getDeffaultStateMapStyle(),
  bounds: null,
  tokenIsPending: false,
  geojsonIsPending: false,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case mapbox.SET_TOKEN:
      return { ...state, token: action.token };

    case mapbox.SET_FIELDS_GEOJSON:
      return { ...state, fieldsGeojson: action.geojson };

    case mapbox.INSERT_FIELDS_GEOJSON: {
      const insertedId = action.feature?.properties?.field_id;
      const features = !insertedId
        ? state.fieldsGeojson.features
        : state.fieldsGeojson.features.filter(
            (feature) => feature?.properties?.field_id !== insertedId
          );

      return {
        ...state,
        fieldsGeojson: {
          type: state.fieldsGeojson.type || 'FeatureCollection',
          bbox: state.fieldsGeojson.bbox || [],
          features: [...(features || []), action.feature],
        },
      };
    }

    case mapbox.SET_CURRENT_ITEM:
      return { ...state, currentItem: action.item };

    case mapbox.SET_ROUTE:
      return { ...state, route: action.item };

    case mapbox.SET_STYLE:
      return { ...state, mapStyle: action.index };

    case mapbox.SET_BOUNDS:
      return { ...state, bounds: action.bounds };

    case mapbox.SET_TOKEN_IS_PENDING:
      return { ...state, tokenIsPending: action.tokenIsPending };

    case mapbox.SET_FIELDS_GEOJSON_IS_PENDING:
      return { ...state, geojsonIsPending: action.geojsonIsPending };

    default:
      return state;
  }
};

// actions
export function setToken(token) {
  return {
    type: mapbox.SET_TOKEN,
    token,
  };
}

export function setCurrentItem(item) {
  return {
    type: mapbox.SET_CURRENT_ITEM,
    item,
  };
}

export function setFieldsGeojson(geojson) {
  return {
    type: mapbox.SET_FIELDS_GEOJSON,
    geojson,
  };
}

export function insertFeatureIntoGeojson(feature) {
  return {
    type: mapbox.INSERT_FIELDS_GEOJSON,
    feature,
  };
}

export function setRoute(item) {
  // Set route after fetch
  // adding additional properties
  return {
    type: mapbox.SET_ROUTE,
    item: {
      ...item,
      features: item.features.map((f) => {
        const featureCopy = { ...f };
        featureCopy.properties.selected = false;
        return featureCopy;
      }),
    },
  };
}

export function selectRouteSegment(index) {
  // set modified route
  return (dispatch, getState) => {
    const oldRoute = getState().mapbox.route;
    const item = {
      ...oldRoute,
      features: oldRoute.features.map((segment, sIndex) => {
        const segmentCopy = { ...segment };
        if (sIndex == index) {
          segmentCopy.properties.selected = !segmentCopy.properties.selected;
          // segment.properties.loaded = !segment.properties.loaded; //for test only
        } else {
          segmentCopy.properties.selected = false;
        }
        return segmentCopy;
      }),
    };

    dispatch({
      type: mapbox.SET_ROUTE,
      item,
    });
  };
}

export function clearRoute() {
  return {
    type: mapbox.SET_ROUTE,
    item: null,
  };
}

export function setFilterLoaded(filterState) {
  return {
    type: mapbox.SET_FILTER_LOADED,
    filterState,
  };
}

export function setStyle(index) {
  localStorage.setItem('mapStyle', index);
  return {
    type: mapbox.SET_STYLE,
    index,
  };
}

export function setBounds(bounds) {
  return {
    type: mapbox.SET_BOUNDS,
    bounds,
  };
}

export function setTokenIsPending(tokenIsPending) {
  return {
    type: mapbox.SET_TOKEN_IS_PENDING,
    tokenIsPending,
  };
}

export function setGeojsonIsPending(geojsonIsPending) {
  return {
    type: mapbox.SET_FIELDS_GEOJSON_IS_PENDING,
    geojsonIsPending,
  };
}

/* API */

export const getMapboxToken = () => async (dispatch) => {
  dispatch(setTokenIsPending(true));
  try {
    const response = await commonGet({ url: urls().mapboxToken });
    dispatch(setToken(response.data.token));
    return response.data;
  } finally {
    dispatch(setTokenIsPending(false));
  }
};

export const addFieldGeojson = (fieldId) => async (dispatch) => {
  dispatch(setGeojsonIsPending(true));
  try {
    const response = await commonGet({
      url: `${urlsWrapper('fields')}/${fieldId}/geojson`,
    });
    dispatch(insertFeatureIntoGeojson(response.data));
    return response.data;
  } catch (e) {
    return [];
  } finally {
    dispatch(setGeojsonIsPending(false));
  }
};

export const getFieldsGeojson = () => async (dispatch) => {
  dispatch(setGeojsonIsPending(true));
  try {
    const response = await commonGet({
      url: `${urlsWrapper('fields')}/geojson`,
    });
    dispatch(setFieldsGeojson(response.data));
    return response.data;
  } finally {
    dispatch(setGeojsonIsPending(false));
  }
};

export const getBounds = (divisionIds, divisions) => async (dispatch) => {
  let url = `${urlsWrapper('bounds')}`;
  if (divisionIds?.length > 0) {
    const divisionId = divisionIds[0];
    if (divisionIds.length === 1) {
      if (divisionId === 0) {
        url = `${urlsWrapper('bounds')}`;
      } else {
        url = `${urlsWrapper('divisions')}/${divisionId}/fields/bounds`;
      }
    } else {
      const division = divisions.find((d) => d.id == divisionId);
      if (division && division.divisiongroup_id) {
        url = `${urlsWrapper('divisionGroups')}/${
          division.divisiongroup_id
        }/fields/bounds`;
      }
    }
  }
  try {
    const response = await commonGet({ url });
    dispatch(setBounds(response.data));
    return response.data;
  } catch (e) {
    return null;
  }
};

export const saveBounds = (bounds) => async (dispatch, getState) => {
  if (!isEqual(bounds, getState().mapbox.bounds)) {
    dispatch(setBounds(bounds));
  }
};

export const resetBounds = () => async (dispatch) => {
  dispatch(setBounds(defaultState.bounds));
};

export const geometryValidation = (geojson) =>
  commonPost({
    url: `${urlsWrapper('geometryValidation')}`,
    data: { geojson },
    name: 'валидация geojson',
  });

// selectors
export const getMapboxSelector = createSelector(
  [(state) => state.mapbox],
  (mapboxItem) => mapboxItem
);
