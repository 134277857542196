export const isSeasonActual = (season, actualSeasons) => {
  if (!actualSeasons?.length) {
    return true;
  }

  if (
    actualSeasons.length === 1 &&
    actualSeasons[0][0] === null &&
    actualSeasons[0][1] === null
  ) {
    return true;
  }

  // eslint-disable-next-line no-restricted-syntax
  for (const [start, end] of actualSeasons) {
    if (
      (start === null || season >= start) &&
      (end === null || season <= end)
    ) {
      return true;
    }
  }

  return false;
};
