import { urls } from 'config';
import { sortListByImportantAndName } from 'utils';
import { convertDictionaryFilters } from 'utils/convertDictionaryFilters';
import {
  saveData,
  createCommonListConstants,
  createCommonListReducer,
  createCommonItemReducer,
  createCommonItemConstants,
  fetchDictionariesItemIfNotExist,
} from './utils';
import { commonGet } from './api';

const constants = createCommonListConstants('PESTS');
const itemConstants = createCommonItemConstants('PEST_ITEM');

// reducer
export const pests = createCommonListReducer(
  constants,
  sortListByImportantAndName
);
export const pestItem = createCommonItemReducer(itemConstants);

// actions
export const getPests = (embedParam = 'none') =>
  saveData(
    `${urls().pests}?embed=${embedParam}`,
    constants.SET_LIST,
    constants.SET_IS_PENDING
  );

export function getPestIdsBySearch(query = '', filters = {}) {
  return commonGet({
    url: `${urls().pests}/search/ids`,
    params: {
      query,
      ...convertDictionaryFilters(filters),
    },
  });
}

function getPestItem(itemId) {
  return commonGet({ url: `${urls().pests}/${itemId}` });
}

const shouldFetchPests = (state) => state.pests.list.length === 0;

export const fetchPestsIfNeeded = (embedParam) => (dispatch, getState) =>
  shouldFetchPests(getState())
    ? dispatch(getPests(embedParam))
    : Promise.resolve();

export const fetchPestItemIfNeeded = (itemId) =>
  fetchDictionariesItemIfNotExist({
    itemId,
    itemStateKey: 'pestItem',
    itemSubStateKey: null,
    itemConstants,
    getDictItem: getPestItem,
  });
