import React, { Suspense } from 'react';
import { connect } from 'react-redux';
import componentLoader from 'utils/componentLoader';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { setFilterDivisions } from 'modules/globalFilters';
import { getAuthCurrentUserSelector, getCurrentUser } from 'modules/auth';
import { env_bayer, PUBLIC_URL } from 'env';
import Loader from 'components/common/Loader';
import { getTopicsSelector } from 'modules/topics';
import routesAdvisor from './routesAdvisor';
import RoutesWrapper from './RoutesWrapper';

const Login = componentLoader(() =>
  import(/* webpackChunkName: "common-Login" */ 'components/common/Login/')
);
const BayerLogin = componentLoader(() =>
  import(
    /* webpackChunkName: "common-BayerLogin" */ 'components/common/BayerLogin/'
  )
);
const AuthView = componentLoader(
  /* webpackChunkName: "routes-AuthView" */ () => import('./AuthView')
);
const AuthAdvisorView = componentLoader(
  /* webpackChunkName: "routes-AuthAdvisorView" */ () =>
    import('./AuthAdvisorView')
);
const AuthCustomersView = componentLoader(
  /* webpackChunkName: "routes-AuthCustomersView" */ () =>
    import('./AuthCustomersView')
);
const CustomerView = componentLoader(
  /* webpackChunkName: "routes-CustomerView" */ () => import('./CustomerView')
);

const RoutesContainer = (appProps) => (
  <Suspense fallback={<Loader />}>
    <Router basename={PUBLIC_URL}>
      <div className="page">
        <Switch>
          <Route
            path="/login"
            render={(props) => {
              // Bayer QuantumTerra
              if (env_bayer === 'true') {
                return <BayerLogin {...props} />;
              }
              return <Login {...props} />;
            }}
          />
          <AuthAdvisorView
            path="/advisor"
            component={(props) => (
              <RoutesWrapper {...appProps} {...props} menu={routesAdvisor} />
            )}
            {...appProps}
          />
          <AuthCustomersView
            path="/customer/:customerId"
            component={(props) => <CustomerView {...appProps} {...props} />}
            {...appProps}
          />
          <AuthView path="/" {...appProps} />
        </Switch>
      </div>
    </Router>
  </Suspense>
);

const routesContainer = connect(
  (state) => ({
    topics: getTopicsSelector(state).list,
    currentUser: getAuthCurrentUserSelector(state),
  }),
  (dispatch) => ({
    setFilterDivisions: () => dispatch(setFilterDivisions()),
    getCurrentUser: () => dispatch(getCurrentUser),
    dispatch,
  })
)(RoutesContainer);

export default routesContainer;
