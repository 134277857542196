import moment from 'moment';
import { combineReducers } from 'redux';
import { createSelector } from 'reselect';

export const constants = {
  SET_FILTER_DIVISIONS: 'GLOBAL_FILTERS_SET_FILTER_DIVISIONS',
  SET_FILTER_DIVISIONS_SELECTED: 'GLOBAL_FILTERS_SET_FILTER_DIVISIONS_SELECTED',
  SET_FILTER_SEASONS: 'GLOBAL_FILTERS_SET_FILTER_SEASONS',
};

// reducers
const filterDivisions = (state = [0], action) => {
  switch (action.type) {
    case constants.SET_FILTER_DIVISIONS:
      return action.filterState;
    default:
      return state;
  }
};

const filterSeasons = (state = moment(new Date()).year(), action) => {
  switch (action.type) {
    case constants.SET_FILTER_SEASONS:
      return action.filterState;
    default:
      return state;
  }
};

export default combineReducers({
  filterDivisions,
  filterSeasons,
});

// actions
export function setFilterDivisions(filterState = [0]) {
  if (filterState) {
    localStorage.setItem('USER_DIVISION_IDS', filterState);
  } else {
    localStorage.removeItem('USER_DIVISION_IDS');
  }
  return {
    type: constants.SET_FILTER_DIVISIONS,
    filterState,
  };
}

export function setFilterSeasons(filterState) {
  return {
    type: constants.SET_FILTER_SEASONS,
    filterState,
  };
}

// selector
export const getFilterSeasonSelector = createSelector(
  [(state) => state.globalFilters.filterSeasons],
  (season) => season
);

export const getFilterDivisionsSelector = createSelector(
  [(state) => state.globalFilters.filterDivisions],
  (filter) => filter
);
